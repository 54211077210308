import * as React from "react";
import {
    Box,
    Collapse,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TableHead,
    Typography,
    IconButton,
    Checkbox,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import SelfScheduleTableHead from "./SelfScheduleTableHead";
import SeverityPill from "../SeverityPill";

import { useAppSelector } from "../../redux/hooks";
import {
    getSelfScheduleCapAndSetState,
    setSelfSchedule,
} from "../../utils/school";
import {
    self_schedule_slots,
    max_num_teams_per_self_schedule_slot,
    self_schedule_events,
    default_all_events_capacities,
} from "../../utils/settings";

type EventRowProps = {
    event: string;
    location: string;
    capacity: number[];
    label: string;
};

function EventRow(props: EventRowProps) {
    const [open, setOpen] = React.useState(false);
    const currentSlots = useAppSelector(
        (state) => state.user.school.self_schedule
    );
    const schoolId = useAppSelector((state) => state.user.school.id);
    const currentEventSlots = useAppSelector(
        (state) => state.user.school.self_schedule[props.label]
    );
    const team_number = useAppSelector(
        (state) => state.user.school.team_number
    );
    const number_of_teams = team_number.length;

    return (
        <>
            <TableRow
                hover
                sx={{
                    "& > *": {
                        borderBottom: "unset",
                    },
                }}
                key={props.event}
                onClick={() => setOpen(!open)}
            >
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                </TableCell>
                <TableCell align="left">
                    <Typography variant="h6">{props.event}</Typography>
                </TableCell>
                <TableCell align="left">{props.location}</TableCell>
                <TableCell align="center">
                    {currentEventSlots[0] === -1 ? (
                        <SeverityPill color="error">not signed up</SeverityPill>
                    ) : (
                        <SeverityPill
                            color="success"
                            sx={{ width: "180px" }}
                        >{`${self_schedule_slots[currentEventSlots[0]].slot}: ${
                            self_schedule_slots[currentEventSlots[0]].time
                        }`}</SeverityPill>
                    )}
                </TableCell>
                {number_of_teams === 2 ? (
                    <TableCell align="center">
                        {currentEventSlots[1] === -1 ? (
                            <SeverityPill color="error">
                                not signed up
                            </SeverityPill>
                        ) : (
                            <SeverityPill
                                color="success"
                                sx={{ width: "180px" }}
                            >{`${
                                self_schedule_slots[currentEventSlots[1]].slot
                            }: ${
                                self_schedule_slots[currentEventSlots[1]].time
                            }`}</SeverityPill>
                        )}
                    </TableCell>
                ) : null}
            </TableRow>
            <TableRow>
                <TableCell
                    style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                    }}
                    colSpan={6}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box
                            sx={{ my: 3 }}
                            display="flex"
                            justifyContent="center"
                        >
                            <Table
                                size="small"
                                aria-label="purchases"
                                sx={{ maxWidth: "550px" }}
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Slot</TableCell>
                                        <TableCell>Time</TableCell>
                                        <TableCell />
                                        <TableCell
                                            align="center"
                                            sx={{ whiteSpace: "nowrap" }}
                                        >
                                            Team A
                                        </TableCell>
                                        {number_of_teams === 2 ? (
                                            <TableCell
                                                align="center"
                                                sx={{ whiteSpace: "nowrap" }}
                                            >
                                                Team B
                                            </TableCell>
                                        ) : null}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {self_schedule_slots.map((s, index) => (
                                        <TableRow key={s.slot}>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                {s.slot}
                                            </TableCell>
                                            <TableCell>{s.time}</TableCell>
                                            <TableCell align="center">
                                                <SeverityPill
                                                    color={
                                                        props.capacity[index] >
                                                        3
                                                            ? "success"
                                                            : props.capacity[
                                                                  index
                                                              ] === 0
                                                            ? "error"
                                                            : "warning"
                                                    }
                                                >
                                                    {`${props.capacity[index]} LEFT`}
                                                </SeverityPill>
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                padding="checkbox"
                                            >
                                                <Checkbox
                                                    onChange={(e) => {
                                                        setSelfSchedule(
                                                            schoolId,
                                                            index,
                                                            props.label,
                                                            0,
                                                            team_number[0],
                                                            structuredClone(
                                                                currentSlots
                                                            )
                                                        );
                                                    }}
                                                    checked={
                                                        currentEventSlots[0] ===
                                                        index
                                                    }
                                                    disabled={
                                                        currentEventSlots[0] !==
                                                            index &&
                                                        props.capacity[
                                                            index
                                                        ] === 0
                                                    }
                                                />
                                            </TableCell>
                                            {number_of_teams === 2 ? (
                                                <TableCell
                                                    align="center"
                                                    padding="checkbox"
                                                >
                                                    <Checkbox
                                                        onClick={() => {
                                                            setSelfSchedule(
                                                                schoolId,
                                                                index,
                                                                props.label,
                                                                1,
                                                                team_number[1],
                                                                structuredClone(
                                                                    currentSlots
                                                                )
                                                            );
                                                        }}
                                                        checked={
                                                            currentEventSlots[1] ===
                                                            index
                                                        }
                                                        disabled={
                                                            currentEventSlots[1] !==
                                                                index &&
                                                            props.capacity[
                                                                index
                                                            ] === 0
                                                        }
                                                    />
                                                </TableCell>
                                            ) : null}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

export default function SelfScheduleTable() {
    const [cap, setCap] = React.useState<{ [index: string]: number[] }>(
        default_all_events_capacities
    );

    React.useEffect(() => {
        getSelfScheduleCapAndSetState(
            setCap,
            max_num_teams_per_self_schedule_slot
        );
    }, []);

    return (
        <Box sx={{ width: "100%" }}>
            <TableContainer component={Paper}>
                <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={"small"}
                >
                    <SelfScheduleTableHead />
                    <TableBody>
                        {self_schedule_events.map((e) => {
                            return (
                                <EventRow
                                    event={e.event}
                                    location={e.location}
                                    capacity={cap[e.label]}
                                    label={e.label}
                                    key={e.event}
                                />
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
