import { jsPDF } from "jspdf";
import {
    logo,
    lato_regular,
    lato_light,
    lato_bold,
} from "../../../assets/invoice";
import { numeric_date_to_string } from "./convert_date";

export const generatePDF = (invoice_input) => {
    const {
        date,
        due_date,
        invoice_num,
        school_name,
        school_address,
        recipient_name,
        recipient_address,
        num_teams,
        fee_per_team,
    } = invoice_input;

    var doc = new jsPDF({ unit: "in", format: [8.5, 11] });
    // Fonts
    doc.addFileToVFS("Lato-Regular-normal.ttf", lato_regular);
    doc.addFont("Lato-Regular-normal.ttf", "Lato-Regular", "normal");
    doc.addFileToVFS("Lato-Light-normal.ttf", lato_light);
    doc.addFont("Lato-Light-normal.ttf", "Lato-Light", "normal");
    doc.addFileToVFS("Lato-Bold-normal.ttf", lato_bold);
    doc.addFont("Lato-Bold-normal.ttf", "Lato-Bold", "normal");

    // Header
    doc.setDrawColor(0);
    doc.setFillColor(54, 79, 104);
    doc.rect(0, 0, 8.5, 1.8, "F");

    doc.addImage(logo, "PNG", 0.36, 0.31, 1.55, 1.05);

    doc.setTextColor(255);
    doc.setFont("Lato-Bold", "normal");
    doc.setFontSize(45);
    doc.text("INVOICE", 1.88, 1.25);

    doc.setFont("Lato-Light", "normal");
    doc.setFontSize(17);
    doc.text("MIT Science Olympiad", 1.9, 0.6);
    doc.setFont("Lato-Regular", "normal"); // set font
    doc.setFontSize(14);
    doc.text("Date:\n\nInvoice #", 6.8, 0.6, null, null, "right");

    let today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    today = mm + "/" + dd + "/" + yyyy;
    // const invoice_date = date.replace("-", "/");
    doc.text(`${date}\n\n${String(invoice_num).padStart(5, "0")}`, 6.9, 0.6);

    // Mid section
    doc.setTextColor(0);
    doc.setFontSize(14);
    doc.text("BILL TO", 0.6, 2.5);
    doc.text("SHIP TO", 3, 2.5);
    doc.text("DUE DATE", 6.1, 2.5);
    // Bill To
    doc.setFont("Lato-Bold", "normal"); // set font
    doc.setFontSize(14);
    const splitSchoolName = doc.splitTextToSize(school_name, 2);
    doc.text(splitSchoolName, 0.6, 2.8);
    doc.setFont("Lato-Regular", "normal");
    doc.text(school_address, 0.6, 3.1);
    // Due Date
    // doc.text("December 5, 2022", 6.1, 2.8);
    doc.text(numeric_date_to_string(due_date), 6.1, 2.8);
    // Ship To
    doc.setFont("Lato-Bold", "normal"); // set font
    doc.text(recipient_name, 3, 2.8);
    doc.setFont("Lato-Regular", "normal");
    doc.text(recipient_address, 3, 3.1);

    // Items
    doc.setDrawColor(0);
    doc.setFillColor(54, 79, 104);
    doc.rect(0.5, 4.25, 7.5, 0.3, "F");

    doc.setFontSize(11);
    doc.setTextColor(255);
    doc.text("Item", 0.6, 4.45);
    doc.text("Quantity", 4.2, 4.45);
    doc.text("Unit Price", 6.3, 4.45, null, null, "right");
    doc.text("Amount", 7.8, 4.45, null, null, "right");

    doc.setTextColor(0);
    doc.text("Team Registration Fee", 0.6, 4.8);
    doc.text(`${num_teams}`, 4.2, 4.8);
    doc.text(`$${fee_per_team}`, 6.3, 4.8, null, null, "right");
    doc.text(`$${num_teams * fee_per_team}`, 7.8, 4.8, null, null, "right");

    // Footer
    doc.setTextColor(0);
    doc.text("Subtotal\n\nTax (0%)\n\nTotal", 6.5, 6.5, null, null, "right");
    doc.text(
        `$${num_teams * fee_per_team}\n\n$0\n\n$${num_teams * fee_per_team}`,
        7.8,
        6.5,
        null,
        null,
        "right"
    );

    doc.setDrawColor(0);
    doc.setFillColor(54, 79, 104);
    doc.rect(4.5, 8, 3.5, 0.8, "F");

    doc.setTextColor(255);
    doc.setFontSize(18);
    doc.text("Balance Due", 4.7, 8.35);
    doc.setFont("Lato-Bold", "normal"); // set font
    doc.setFontSize(24);
    doc.text(`$${num_teams * fee_per_team}.00`, 7.8, 8.55, null, null, "right");

    doc.save("mit_scioly_invoice.pdf");
};
