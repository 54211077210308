import React from "react";
import { Box, Typography, Link } from "@mui/material";

export default function DashboardSidebarFooter() {
    const date = new Date();
    return (
        <Box
            mx={2}
            my={2}
            alignItems="center"
            sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
            }}
        >
            <Link href="https://scioly.mit.edu/privacy" fontSize={12} mb={1}>
                Privacy Policy
            </Link>
            <Typography fontSize={12}>
                &copy; MIT Science Olympiad {date.getFullYear()}
            </Typography>
        </Box>
    );
}
