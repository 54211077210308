import {
    addDoc,
    collection,
    doc,
    updateDoc,
    writeBatch,
    Timestamp,
} from "firebase/firestore";
import { db } from "../firebase";

import { User } from "../types/users";
import React, { SetStateAction } from "react";
import store from "../redux/store";
import { setError, clearError, setSuccess } from "../redux";

export const formatPhoneNumber = (phone_number: string) => {
    return `(${phone_number.substring(0, 3)}) 
    ${phone_number.substring(3, 6)}-${phone_number.substring(6)}`;
};

// Toggle user role to be Admin or Coach
export const updateUserRole = async (userId: string, newRole: string) => {
    const ref = doc(db, "users", userId);

    let data_to_save = {
        role: newRole,
    };

    try {
        await updateDoc(ref, data_to_save);
    } catch (error) {
        console.error(error);
    }
};

export const updateProfile = async (
    data: User,
    setChanged: React.Dispatch<SetStateAction<boolean>>
) => {
    try {
        await updateDoc(doc(db, "users", data.id), {
            ...data,
        });
        setChanged(false);
        store.dispatch(setSuccess("Successfully saved!"));
    } catch (error: any) {
        let message = "";
        switch (error.code) {
            default: {
                message = error.message;
                break;
            }
        }
        store.dispatch(setError(message));
        console.error(error);
    }
};
