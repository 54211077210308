import React, { useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import type {} from "@mui/lab/themeAugmentation";
import LoadingButton from "@mui/lab/LoadingButton";

import { RegistrationData } from "../../types/authentication";

type RegisterProp = {
    handleRegister: (formData: RegistrationData) => void;
    loading: boolean;
};

function RegisterInfo(props: RegisterProp) {
    const [phoneNumber, setPhoneNumber] = useState("");
    const handlePhoneChange = (e: any) => {
        const onlyNums = e.target.value.replace(/[^0-9]/g, "");
        if (onlyNums.length < 4) {
            setPhoneNumber(onlyNums);
        } else if (onlyNums.length < 7) {
            const number = onlyNums.replace(/(\d{3})(\d{0,})/, "($1) $2");
            setPhoneNumber(number);
        } else if (onlyNums.length <= 10) {
            const number = onlyNums.replace(
                /(\d{3})(\d{3})(\d{0,})/,
                "($1) $2-$3"
            );
            setPhoneNumber(number);
        }
        if (e.target.value !== "") {
            setFormErrors({
                ...formErrors,
                phone_number: "",
            });
        }
    };

    const getFormData = (e: React.SyntheticEvent<HTMLFormElement>) => {
        const data = new FormData(e.currentTarget);
        return {
            first_name: String(data.get("first_name")).trim(),
            last_name: String(data.get("last_name")).trim(),
            email: String(data.get("email")).trim(),
            password: String(data.get("password")),
            confirm_password: String(data.get("confirm_password")),
            phone_number: String(data.get("phone_number")),
            access_code: String(data.get("access_code")).trim(),
        };
    };

    const initialError = {
        first_name: "",
        last_name: "",
        phone_number: "",
        email: "",
        access_code: "",
        password: "",
        confirm_password: "",
    };
    const [formErrors, setFormErrors] = useState(initialError);

    const validateForm = (data: RegistrationData) => {
        let result = true;
        let newErrors = { ...formErrors };
        if (data.first_name === "") {
            newErrors.first_name = "Please enter your first name.";
            result = false;
        }
        if (data.last_name === "") {
            newErrors.last_name = "Please enter your first name.";
            result = false;
        }
        if (
            data.phone_number === "" ||
            !data.phone_number.match(/\(\d{3}\) \d{3}-\d{4}/)
        ) {
            newErrors.phone_number = "Please enter a valid phone number.";
            result = false;
        }
        if (
            data.email === "" ||
            !data.email.match(
                /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
            )
        ) {
            newErrors.email = "Please enter a valid email.";
            result = false;
        }
        if (data.access_code === "") {
            newErrors.access_code = "Please enter the provided access code.";
            result = false;
        }
        if (data.password === "") {
            newErrors.password = "Please enter a password.";
            result = false;
        }
        if (data.password !== data.confirm_password) {
            newErrors.confirm_password = "Passwords must match.";
            result = false;
        }
        setFormErrors(newErrors);
        return result;
    };

    // Check for password
    const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault();
        const data: RegistrationData = getFormData(e);
        if (validateForm(data)) {
            props.handleRegister(data);
        }
    };

    return (
        <>
            <Box
                component="form"
                noValidate
                maxWidth="550px"
                my={3}
                onSubmit={handleSubmit}
            >
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            required
                            fullWidth
                            id="first_name"
                            label="First Name"
                            name="first_name"
                            type="text"
                            helperText={formErrors.first_name}
                            error={formErrors.first_name !== ""}
                            FormHelperTextProps={{
                                sx: { fontSize: 14 },
                            }}
                            onChange={(e) => {
                                if (e.target.value !== "") {
                                    setFormErrors({
                                        ...formErrors,
                                        first_name: "",
                                    });
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            required
                            fullWidth
                            id="last_name"
                            label="Last Name"
                            name="last_name"
                            type="text"
                            helperText={formErrors.last_name}
                            error={formErrors.last_name !== ""}
                            FormHelperTextProps={{
                                sx: { fontSize: 14 },
                            }}
                            onChange={(e) => {
                                if (e.target.value !== "") {
                                    setFormErrors({
                                        ...formErrors,
                                        last_name: "",
                                    });
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            id="phone_number"
                            label="Phone Number"
                            name="phone_number"
                            type="text"
                            value={phoneNumber}
                            helperText={formErrors.phone_number}
                            error={formErrors.phone_number !== ""}
                            FormHelperTextProps={{
                                sx: { fontSize: 14 },
                            }}
                            onChange={handlePhoneChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            type="email"
                            helperText={formErrors.email}
                            error={formErrors.email !== ""}
                            FormHelperTextProps={{
                                sx: { fontSize: 14 },
                            }}
                            onChange={(e) => {
                                if (e.target.value !== "") {
                                    setFormErrors({
                                        ...formErrors,
                                        email: "",
                                    });
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            id="access_code"
                            label="Access Code"
                            name="access_code"
                            type="text"
                            helperText={formErrors.access_code}
                            error={formErrors.access_code !== ""}
                            FormHelperTextProps={{
                                sx: { fontSize: 14 },
                            }}
                            onChange={(e) => {
                                if (e.target.value !== "") {
                                    setFormErrors({
                                        ...formErrors,
                                        access_code: "",
                                    });
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            id="password"
                            label="Password"
                            name="password"
                            type="password"
                            helperText={formErrors.password}
                            error={formErrors.password !== ""}
                            FormHelperTextProps={{
                                sx: { fontSize: 14 },
                            }}
                            onChange={(e) => {
                                if (e.target.value !== "") {
                                    setFormErrors({
                                        ...formErrors,
                                        password: "",
                                    });
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            id="confirm_password"
                            label="Confirm Password"
                            name="confirm_password"
                            type="password"
                            helperText={formErrors.confirm_password}
                            error={formErrors.confirm_password !== ""}
                            FormHelperTextProps={{
                                sx: { fontSize: 14 },
                            }}
                            onChange={(e) => {
                                if (e.target.value !== "") {
                                    setFormErrors({
                                        ...formErrors,
                                        confirm_password: "",
                                    });
                                }
                            }}
                        />
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3, fontSize: 16 }}
                        fullWidth
                        loading={props.loading}
                    >
                        Register
                    </LoadingButton>
                </Box>
            </Box>
        </>
    );
}

export default RegisterInfo;
