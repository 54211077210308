import React, { useContext, useState } from "react";
import AnimatedTitle from "../components/animation/AnimatedTitle";

import { Card, Box, Typography, Link } from "@mui/material";
import { handleRegistrationRequest } from "../utils/authentication";
import { Navigate } from "react-router-dom";

import RegisterInfo from "../components/authentication/RegisterInfo";
import FormFooter from "../components/authentication/FormFooter";
import { RegistrationData } from "../types/authentication";
import { AuthContext } from "../contexts/AuthContext";

function Register() {
    const [loading, setLoading] = useState(false);

    const handleRegister = (formData: RegistrationData) => {
        handleRegistrationRequest(setLoading, formData);
    };

    const user = useContext(AuthContext);

    return user ? (
        <Navigate to="/" />
    ) : (
        <Box
            component="main"
            sx={{ maxWidth: "700px", mx: "auto", px: 2, pb: 5 }}
        >
            <Card
                variant="outlined"
                sx={{
                    mt: "20%",
                    border: "none",
                    // elevation: "16",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        mt: 3,
                        mb: 3,
                        mx: 3,
                    }}
                >
                    <AnimatedTitle />
                    <Typography variant="h5" pt={3}>
                        {"Create\u00a0an\u00a0Account"}
                    </Typography>
                    <RegisterInfo
                        handleRegister={handleRegister}
                        loading={loading}
                    />
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            mb: "25px",
                        }}
                    >
                        <Typography variant="subtitle1">
                            Already have an account?{" "}
                            <Link href="/login">Log in.</Link>
                        </Typography>
                    </Box>
                </Box>
            </Card>
            <FormFooter />
        </Box>
    );
}

export default Register;
