import React, { Fragment, useState, useEffect } from "react";
import { visuallyHidden } from "@mui/utils";
import {
    Box,
    Checkbox,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
} from "@mui/material";
import { Order } from "../users/utils";
import { Student } from "../../types/student";
import { headCells } from "./utils";

type AlignType = "left" | "right" | "inherit" | "center" | "justify";

type TableHeadProps = {
    order: Order;
    orderBy: keyof Student;
    onRequestSort: (
        event: React.MouseEvent<unknown>,
        property: keyof Student
    ) => void;
    student_ids: string[];
    selected: Set<string>;
    setSelected: React.Dispatch<React.SetStateAction<Set<string>>>;
};

export default function RosterTableHead(props: TableHeadProps) {
    const {
        order,
        orderBy,
        onRequestSort,
        student_ids,
        selected,
        setSelected,
    } = props;

    const createSortHandler =
        (property: keyof Student) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    const [check, setCheck] = useState(student_ids.length === selected.size);
    const [indeterminate, setIndeterminate] = useState(
        student_ids.length !== selected.size && selected.size !== 0
    );

    useEffect(() => {
        setCheck(
            student_ids.length !== 0 && selected.size === student_ids.length
        );
        setIndeterminate(
            student_ids.length !== selected.size && selected.size !== 0
        );
    }, [selected, student_ids]);

    const handleClick = () => {
        if (check) {
            setSelected(new Set());
        } else {
            setSelected(new Set(student_ids));
        }
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        checked={check}
                        indeterminate={indeterminate}
                        onClick={handleClick}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align as AlignType}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(
                                headCell.id as keyof Student
                            )}
                            disabled={
                                headCell.id === "options" ||
                                headCell.id === "index"
                            }
                        >
                            <div>{headCell.label}</div>
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
