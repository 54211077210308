import React, { useContext } from "react";
import AnimatedTitle from "../components/animation/AnimatedTitle";

import { Card, Box, Typography, Link } from "@mui/material";
import { Navigate } from "react-router-dom";

import { logIn } from "../utils/authentication";
import LoginInfo from "../components/authentication/LoginInfo";
import FormFooter from "../components/authentication/FormFooter";
import { LoginData } from "../types/authentication";
import { AuthContext } from "../contexts/AuthContext";

function Login() {
    const user = useContext(AuthContext);

    const handleLogin = (formData: LoginData) => {
        logIn(formData.email, formData.password);
    };

    return user ? (
        <Navigate to="/" />
    ) : (
        <Box component="main" sx={{ maxWidth: "600px", mx: "auto", px: 2 }}>
            <Card
                variant="outlined"
                sx={{
                    mt: "30%",
                    border: "none",
                    //elevation: "16",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        mt: 3,
                        mb: 3,
                        mx: 3,
                    }}
                >
                    <AnimatedTitle />
                    <Typography variant="h5" pt={3}>
                        Log In
                    </Typography>
                    <LoginInfo handleLogin={handleLogin} />
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            mb: "25px",
                        }}
                    >
                        <Typography variant="subtitle1">
                            Don't have an account?{" "}
                            <Link href="/register">Create an account.</Link>
                        </Typography>
                    </Box>
                </Box>
            </Card>
            <FormFooter />
        </Box>
    );
}

export default Login;
