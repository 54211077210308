import { useState } from "react";
import { Button, Box, Typography, Dialog } from "@mui/material";
import { Student } from "../../../types/student";
import { deleteStudent } from "../../../utils/roster";

type DeleteOptionProps = {
    student: Student;
    openConfirm: boolean;
    setOpenConfirm: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function DeleteOptionDialog(props: DeleteOptionProps) {
    const { student, openConfirm, setOpenConfirm } = props;

    const handleDelete = () => {
        try {
            deleteStudent(student);
            setOpenConfirm(false);
        } catch (error) {}
    };

    return (
        <Dialog
            open={openConfirm}
            onClose={() => setOpenConfirm(false)}
            fullWidth
        >
            <Box my={3} mx={3}>
                <Typography variant="h5" mb={3}>
                    Delete Student
                </Typography>
                <Typography variant="body1">
                    Are you sure you want to delete {student.first}{" "}
                    {student.last}?
                </Typography>
                <Box
                    pt={3}
                    sx={{ display: "flex", flexDirection: "row-reverse" }}
                >
                    <Button
                        variant="contained"
                        color="error"
                        onClick={handleDelete}
                    >
                        <Typography variant="body1">Delete</Typography>
                    </Button>
                    <Button onClick={() => setOpenConfirm(false)}>
                        <Typography variant="body1" color="neutral.secondary">
                            Cancel
                        </Typography>
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
}
