import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import thunk from "redux-thunk";
import popupReducer from "./popup/popupReducer";
import authReducer from "./auth/authReducer";
import userReducer from "./user/userReducer";

const middleware = [thunk];

const rootReducer = combineReducers({
    popup: popupReducer,
    auth: authReducer,
    user: userReducer,
});

// redux-thunk should automatically be added
const store = configureStore({
    reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
