import React from "react";
import {
    Box,
    Button,
    Typography,
    Card,
    CardContent,
    CardActions,
} from "@mui/material";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { useAppSelector } from "../../../redux/hooks";
import {
    tournament_schedule_link,
    num_teams_per_slot,
    slot_times,
    events,
} from "../../../utils/settings";

export default function Schedule() {
    const teams: Array<string> = useAppSelector(
        (state) => state.user.school.team_number
    );

    const team_num: string =
        teams.length === 2 ? `${teams[0]}, ${teams[1]}` : teams[0];

    const parsed_int_team_num: number = +teams[0].substring(1, 4);

    let team_grouping: number = Math.floor(
        (parsed_int_team_num - 1) / num_teams_per_slot
    );

    team_grouping = team_grouping < 0 ? 0 : team_grouping; // For dummy teams like C000

    const self_scheduled_slots: { [index: string]: number[] } = useAppSelector(
        (state) => state.user.school.self_schedule
    );

    const slots = [
        ["", team_num, "", "", "", "", ""],
        ["", "", team_num, "", "", "", ""],
        ["", "", "", team_num, "", "", ""],
        ["", "", "", "", team_num, "", ""],
        ["", "", "", "", "", team_num, ""],
        ["", "", "", "", "", "", team_num],
    ];

    const groupings: { [index: string]: string[] } = {
        Group1: slots[team_grouping % 6],
        Group2: slots[(team_grouping + 1) % 6],
        Group3: slots[(team_grouping + 2) % 6],
        Group4: slots[(team_grouping + 3) % 6],
        Group5: slots[(team_grouping + 4) % 6],
        Group6: slots[(team_grouping + 5) % 6],
    };

    const getSelfScheduledEventSlots = (event_label: string) => {
        const initial: Array<Array<string>> = [[], [], [], [], [], []];
        let no_self_schedule_for_any_teams = true;

        try {
            self_scheduled_slots[event_label].forEach(
                (e: number, i: number) => {
                    if (e !== -1) {
                        initial[e].push(teams[i]);
                        no_self_schedule_for_any_teams = false;
                    }
                }
            );
        } catch (error) {
            // TODO: better try catch here (if there is no matching event_label in state)
            console.error(error);
        }

        return no_self_schedule_for_any_teams
            ? ["", "Self Schedule", "", "", "", "", ""]
            : [
                  "", //put back the empty cell for impound
                  ...initial.map((e) =>
                      e.length <= 1 ? String(e) : e.join(", ")
                  ),
              ];
    };

    const rows = events.map((e) => {
        return {
            ...e,
            slot: e.selfschedule
                ? getSelfScheduledEventSlots(e.label)
                : groupings[e.color],
        };
    });

    const slotColumnCommonFields: Partial<GridColDef> = {
        sortable: true,
        filterable: false,
        pinnable: false,
        hideable: false,
        minWidth: 105,
        flex: 1,
        align: "center",
        headerAlign: "center",
        cellClassName: ({ row, field, value }: GridCellParams) =>
            value ? row.color : "",
        colSpan: ({ row, field, value }: GridCellParams) => {
            return value === "Self Schedule" ? 6 : 1;
        },
        headerClassName: "schedule-header",
    };

    const columns: GridColDef[] = [
        {
            field: "event",
            headerName: "Event",
            headerClassName: "schedule-header",
            cellClassName: ({ row, field, value }: GridCellParams) => row.color,
            width: 200,
            hideable: false,
        },
        {
            field: "location",
            headerName: "Location",
            headerClassName: "schedule-header",
            align: "center",
            width: 100,
            cellClassName: ({ row, field, value }: GridCellParams) => row.color,
        },
        {
            field: "column0",
            headerName: slot_times[0],
            valueGetter: ({ row }) => (row.impound ? "Impound" : ""),
            ...slotColumnCommonFields,
        },
        ...[1, 2, 3, 4, 5, 6].map((i: number) => ({
            field: `column${i}`,
            headerName: slot_times[i],
            valueGetter: ({ row, field, value }: GridCellParams) => row.slot[i],
            ...slotColumnCommonFields,
        })),
    ];

    const rootStyles = {
        px: 5,
        "& .Group1": {
            backgroundColor: "rgba(157, 255, 118, 0.5)",
        },
        "& .Group2": {
            backgroundColor: "rgba(10, 150, 255, 0.5)",
        },
        "& .Group3": {
            backgroundColor: "rgba(200, 150, 255, 0.5)",
        },
        "& .Group4": {
            backgroundColor: "rgba(255, 120, 120, 0.5)",
        },
        "& .Group5": {
            backgroundColor: "rgba(255, 180, 0, 0.5)",
        },
        "& .Group6": {
            backgroundColor: "rgba(255, 255, 0, 0.5)",
        },
        "& .Group7": {
            backgroundColor: "rgba(150, 150, 150, 0.5)",
        },
        "& .Group8": {
            backgroundColor: "rgba(200, 200, 200, 0.5)",
        },
        "& .schedule-header": {
            backgroundColor: "neutral.800",
        },
    };
    return (
        <Card>
            <CardContent sx={rootStyles}>
                <Typography variant="h4" sx={{ mb: 2 }}>
                    EVENT SCHEDULE
                </Typography>
                <DataGrid
                    columns={columns}
                    rows={rows}
                    initialState={{}}
                    rowHeight={30}
                    headerHeight={30}
                    autoHeight
                    disableExtendRowFullWidth
                    disableSelectionOnClick
                    disableColumnMenu
                    hideFooter
                    showCellRightBorder
                    showColumnRightBorder
                    sx={{ borderRight: 0 }}
                />
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        mt: 2,
                    }}
                >
                    <Button
                        onClick={(e) => {
                            window.open(`${tournament_schedule_link}`);
                        }}
                    >
                        View Full Schedule
                    </Button>
                </Box>
            </CardContent>
        </Card>
    );
}
