import React from "react";
import { Avatar, Card, CardContent, Typography, Grid } from "@mui/material";

type GenericCardProps = {
    children: React.ReactNode;
    title: string;
};

export default function GenericCard(props: GenericCardProps) {
    return (
        <>
            <Card
                sx={{
                    height: "100%",
                }}
            >
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h6">{props.title}</Typography>
                        </Grid>
                        <Grid item>{props.children}</Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
}
