import { forwardRef } from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

import { RootState } from "../../redux/store";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { clearError, clearSuccess } from "../../redux";

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function PopupMessage() {
    const popup_error = useAppSelector((state) => state.popup.error);
    const popup_success = useAppSelector((state) => state.popup.success);
    const dispatch = useAppDispatch();

    const handleErrorClose = () => {
        dispatch(clearError());
    };

    const handleSuccessClose = () => {
        dispatch(clearSuccess());
    };

    return (
        <>
            <Stack spacing={8} sx={{ width: "100%" }}>
                <Snackbar
                    open={popup_error !== ""}
                    autoHideDuration={8000}
                    onClose={handleErrorClose}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                    <Alert
                        onClose={handleErrorClose}
                        severity="error"
                        sx={{ width: "100%" }}
                    >
                        {popup_error}
                    </Alert>
                </Snackbar>
                <Snackbar
                    open={popup_success !== ""}
                    autoHideDuration={3000}
                    onClose={handleSuccessClose}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                    <Alert
                        onClose={handleSuccessClose}
                        severity="success"
                        sx={{ width: "100%" }}
                    >
                        {popup_success}
                    </Alert>
                </Snackbar>
            </Stack>
        </>
    );
}

export default PopupMessage;
