import React, { useEffect, useState } from "react";
import {
    Button,
    Typography,
    Dialog,
    Box,
    Grid,
    MenuItem,
    TextField,
} from "@mui/material";
import { Student, UpdateStudent } from "../../../types/student";
import { editStudent } from "../../../utils/roster";

type EditOptionProps = {
    student: Student;
    openConfirm: boolean;
    setOpenConfirm: React.Dispatch<React.SetStateAction<boolean>>;
    students_in_team: Student[];
};

type EditStudentForm = {
    first: string;
    last: string;
    grade: number;
    alternate: boolean;
};

export default function EditOptionDialog(props: EditOptionProps) {
    const { student, openConfirm, setOpenConfirm } = props;
    const [alternateStatus, setAlternateStatus] = useState<boolean>(
        student.alternate
    );

    const getFormData = (e: React.SyntheticEvent<HTMLFormElement>) => {
        const data = new FormData(e.currentTarget);
        return {
            first: String(data.get("first")).trim(),
            last: String(data.get("last")).trim(),
            grade: Number(data.get("grade")),
            alternate: Boolean(
                String(data.get("alternate")) === "yes" ? true : false
            ),
        };
    };

    const initialError = {
        first: "",
        last: "",
        grade: "",
        alternate: "",
    };

    const [formErrors, setFormErrors] = useState(initialError);

    const validateForm = (data: EditStudentForm) => {
        let result = true;
        let newErrors = { ...formErrors };
        if (data.first === "") {
            newErrors.first = "Please enter first name.";
            result = false;
        }
        if (data.last === "") {
            newErrors.last = "Please enter last name.";
            result = false;
        }
        if (data.grade === 0) {
            // 0 means grade wasn't input
            newErrors.grade = "Please enter grade.";
            result = false;
        }
        setFormErrors(newErrors);
        return result;
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const data = getFormData(e);
        const student_to_save: UpdateStudent = {
            ...data,
            id: student.id,
        };
        if (validateForm(data)) {
            try {
                setOpenConfirm(false);
                editStudent(
                    student_to_save,
                    student.id,
                    props.students_in_team,
                    student.release_form
                );
            } catch (error) {}
        }
    };

    const handleClose = () => {
        setOpenConfirm(false);
        setFormErrors(initialError);
    };

    return (
        <Dialog
            open={openConfirm}
            onClose={handleClose}
            fullWidth
            maxWidth="md"
            keepMounted={false}
        >
            <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                my={3}
                mx={3}
            >
                <Typography variant="h5" mb={3}>
                    Edit Student
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={2}>
                        <TextField
                            label="Grade"
                            select
                            fullWidth
                            required
                            id="grade"
                            name="grade"
                            defaultValue={student.grade ?? ""}
                            helperText={formErrors.grade}
                            error={formErrors.grade !== ""}
                            FormHelperTextProps={{
                                sx: { fontSize: 14 },
                            }}
                        >
                            <MenuItem value={9}>9</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={11}>11</MenuItem>
                            <MenuItem value={12}>12</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <TextField
                            label="Alternate"
                            select
                            fullWidth
                            required
                            id="alternate"
                            name="alternate"
                            // helperText={formErrors.alternate_status}
                            // error={formErrors.alternate_status !== ""}
                            FormHelperTextProps={{
                                sx: { fontSize: 14 },
                            }}
                            value={alternateStatus ? "yes" : "no"}
                            onChange={() => {
                                setAlternateStatus(!alternateStatus);
                            }}
                        >
                            <MenuItem value={"no"}>No</MenuItem>
                            <MenuItem value={"yes"}>Yes</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            required
                            fullWidth
                            id="first"
                            label="First Name"
                            name="first"
                            type="text"
                            defaultValue={student.first ?? ""}
                            helperText={formErrors.first}
                            error={formErrors.first !== ""}
                            FormHelperTextProps={{
                                sx: { fontSize: 14 },
                            }}
                            onChange={(e) => {
                                if (e.target.value !== "") {
                                    setFormErrors({
                                        ...formErrors,
                                        first: "",
                                    });
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            required
                            fullWidth
                            id="last"
                            label="Last Name"
                            name="last"
                            type="text"
                            defaultValue={student.last ?? ""}
                            helperText={formErrors.last}
                            error={formErrors.last !== ""}
                            FormHelperTextProps={{
                                sx: { fontSize: 14 },
                            }}
                            onChange={(e) => {
                                if (e.target.value !== "") {
                                    setFormErrors({
                                        ...formErrors,
                                        last: "",
                                    });
                                }
                            }}
                        />
                    </Grid>
                </Grid>
                <Box
                    pt={3}
                    sx={{
                        display: "flex",
                        flexDirection: "row-reverse",
                    }}
                >
                    <Button variant="contained" type="submit" autoFocus>
                        <Typography variant="body1" color="neutral.white">
                            Save
                        </Typography>
                    </Button>
                    <Button onClick={handleClose}>
                        <Typography variant="body1" color="neutral.secondary">
                            Cancel
                        </Typography>
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
}
