import React from "react";
import { Box, CircularProgress } from "@mui/material";

function Loading() {
    return (
        <Box
            sx={{
                backgroundColor: "background.default",
                width: "100vw",
                height: "100vh",
                display: "flex",
            }}
        >
            <CircularProgress size={80} sx={{ mx: "auto", my: "auto" }} />
        </Box>
    );
}

export default Loading;
