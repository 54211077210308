import React from "react";
import { auth } from "../firebase";
import { setDoc, collection, doc, Timestamp } from "firebase/firestore";
import { db } from "../firebase";
import { User } from "../types/users";
import { RegistrationData } from "../types/authentication";
import { getFunctions, httpsCallable } from "firebase/functions";

import store from "../redux/store";
import {
    loginRequest,
    loginFailure,
    logout_auth,
} from "../redux/auth/authAction";
import { logout_user } from "../redux/user/userAction";
import { setError, clearError } from "../redux";

const createAccount = async (data: RegistrationData, school: string) => {
    try {
        await auth
            .createUserWithEmailAndPassword(data.email, data.password)
            .then((userCredential) => {
                const user = userCredential.user;
                if (user) {
                    setDoc(doc(db, "users", user.uid), {
                        email: data.email.toLowerCase(),
                        first_name: data.first_name,
                        last_name: data.last_name,
                        phone_number: data.phone_number,
                        created_at: Timestamp.now(),
                        role: "coach",
                        school: school,
                    });
                }
            });
    } catch (error: any) {
        let message = "";
        switch (error.code) {
            case "auth/email-already-in-use": {
                message =
                    "The email address is already in use by another account.";
                break;
            }
            default: {
                message = error.message;
                break;
            }
        }
        store.dispatch(loginFailure());
        store.dispatch(setError(message));
        console.error(error);
    }
};

export const logIn = async (email: string, password: string) => {
    store.dispatch(loginRequest());
    try {
        await auth.signInWithEmailAndPassword(email, password);
        // login success should be set by onAuthStateChanged
    } catch (error: any) {
        let message = "";
        switch (error.code) {
            case "auth/wrong-password": {
                message = "Incorrect password.";
                break;
            }
            default: {
                message = error.message;
                break;
            }
        }
        store.dispatch(loginFailure());
        store.dispatch(setError(message));
        console.error(error);
    }
};

export const logOut = async () => {
    await auth.signOut();
    store.dispatch(logout_auth());
    store.dispatch(logout_user());
    store.dispatch(clearError());
};

const functions = getFunctions();
const checkAccessCodeCF = httpsCallable(functions, "checkAccessCode");

const checkAccessCode = async (code: string, email: string) => {
    try {
        let result = await checkAccessCodeCF({ code: code, email: email });
        return result.data; // if successful return {permission: boolean, message: string, email: string}
    } catch (error: any) {
        let message = "";
        switch (error.code) {
            default: {
                message = error.message;
                break;
            }
        }
        store.dispatch(setError(message));
        console.error(error);
        return { permission: false };
    }
};

// combines account creation and checking access code
export const handleRegistrationRequest = async (
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    data: RegistrationData
) => {
    setLoading(true);
    //await createAccount(data, "2XXT2V4bK8f4wC1GyYL1");
    //setLoading(false);
    const result: any = await checkAccessCode(data.access_code, data.email);
    if (result.permission) {
        await createAccount(data, result?.school as string);
        setLoading(false);
    } else {
        setLoading(false);
    }
};
