import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "./theme";
import ProtectedRoute from "./components/authentication/ProtectedRoute";
import { AuthProvider } from "./contexts/AuthContext";
import { AnimatePresence } from "framer-motion";

import { Provider } from "react-redux";
import store from "./redux/store";

import { Roles } from "./types/roles";

import PopupMessage from "./components/popup/PopupMessage";
import Home from "./pages/Home";
import SelfSchedule from "./pages/SelfSchedule";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Roster from "./pages/Roster";
import Users from "./pages/Users";
import Schools from "./pages/Schools";
import Invoice from "./pages/Invoice";
import Settings from "./pages/Settings";
import Email from "./pages/Email";
import TournamentSetup from "./pages/TournamentSetup";
import ErrorBoundary from "./components/ErrorBoundary";

function App() {
    //const location = useLocation();

    return (
        <ErrorBoundary>
            <Provider store={store}>
                <AuthProvider>
                    <ThemeProvider theme={createTheme({ direction: "ltr" })}>
                        <PopupMessage />
                        <AnimatePresence mode="wait">
                            <Routes
                                location={useLocation()}
                                key={useLocation().pathname}
                            >
                                <Route
                                    path="/"
                                    element={
                                        <ProtectedRoute
                                            roles={[Roles.admin, Roles.coach]}
                                        >
                                            <Home />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/selfschedule"
                                    element={
                                        <ProtectedRoute
                                            roles={[Roles.admin, Roles.coach]}
                                        >
                                            <SelfSchedule />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/roster"
                                    element={
                                        <ProtectedRoute
                                            roles={[Roles.admin, Roles.coach]}
                                        >
                                            <Roster />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/users"
                                    element={
                                        <ProtectedRoute roles={[Roles.admin]}>
                                            <Users />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/schools"
                                    element={
                                        <ProtectedRoute roles={[Roles.admin]}>
                                            <Schools />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/invoice"
                                    element={
                                        <ProtectedRoute roles={[Roles.admin]}>
                                            <Invoice />
                                        </ProtectedRoute>
                                    }
                                />
                                {/* <Route
                                    path="/email"
                                    element={
                                        <ProtectedRoute roles={[Roles.admin]}>
                                            <Email />
                                        </ProtectedRoute>
                                    }
                                /> */}
                                <Route
                                    path="/settings"
                                    element={
                                        <ProtectedRoute
                                            roles={[Roles.admin, Roles.coach]}
                                        >
                                            <Settings />
                                        </ProtectedRoute>
                                    }
                                />
                                {/* <Route
                                    path="/tournament_setup"
                                    element={
                                        <ProtectedRoute roles={[Roles.admin]}>
                                            <TournamentSetup />
                                        </ProtectedRoute>
                                    }
                                /> */}
                                <Route path="/login" element={<Login />} />
                                <Route
                                    path="/register"
                                    element={<Register />}
                                />
                                <Route path="*" element={<Login />} />
                            </Routes>
                        </AnimatePresence>
                    </ThemeProvider>
                </AuthProvider>
            </Provider>
        </ErrorBoundary>
    );
}

export default App;
