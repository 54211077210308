import { School } from "../../types/school";
import { DocumentReference } from "firebase/firestore";

export type Order = "asc" | "desc";

type HeadCell = {
    disablePadding: boolean;
    sort_id?: keyof School;
    label: string;
    numeric: boolean;
    sortable: boolean;
};

export const headCells: readonly HeadCell[] = [
    {
        sort_id: "name",
        numeric: false,
        disablePadding: true,
        label: "Name",
        sortable: true,
    },
    {
        sort_id: "team_number",
        numeric: true,
        disablePadding: true,
        label: "# of Teams",
        sortable: true,
    },
    {
        sort_id: "team_number",
        numeric: true,
        disablePadding: true,
        label: "Team #",
        sortable: true,
    },
    {
        numeric: true,
        disablePadding: true,
        label: "Release Form",
        sortable: false,
    },
    {
        sort_id: "payment",
        numeric: false,
        disablePadding: true,
        label: "Payment",
        sortable: true,
    },
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (
    a: {
        [key in Key]:
            | number
            | string
            | string[]
            | { [index: string]: number[] };
    },
    b: {
        [key in Key]:
            | number
            | string
            | string[]
            | { [index: string]: number[] };
    }
) => number {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
