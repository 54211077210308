import {
    Avatar,
    Box,
    Grid,
    Card,
    CardContent,
    Typography,
} from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
import SeverityPill from "../../SeverityPill";
import { useAppSelector } from "../../../redux/hooks";

export default function TeamInfo() {
    const schoolInfo = useAppSelector((state) => state.user.school);
    const payment = useAppSelector((state) => state.user.school.payment);

    return (
        <Card
            sx={{
                height: "100%",
            }}
        >
            <CardContent>
                <Typography variant="h4" mb={1}>
                    TEAM INFO
                </Typography>
                <Typography color="neutral.secondary" variant="caption">
                    School:
                </Typography>
                <Typography color="neutral.main" variant="h5" mx={2}>
                    {schoolInfo.name}
                </Typography>
                <Typography color="neutral.secondary" variant="caption">
                    Team(s):
                </Typography>
                <Typography variant="h5" ml={2} noWrap>
                    {schoolInfo.team_number.length === 2
                        ? schoolInfo.team_number.join(", ")
                        : schoolInfo.team_number[0]}
                </Typography>
                {/* <Typography color="neutral.secondary" variant="caption">
                    Payment:
                </Typography>
                <Box display="flex" justifyContent="center" pt={2}>
                    {payment === "completed" ? (
                        <SeverityPill color="success">
                            <Typography variant="h6" mx={2}>
                                Completed
                            </Typography>
                        </SeverityPill>
                    ) : (
                        <SeverityPill color="error">
                            <Typography variant="h6" mx={2}>
                                Not Processed
                            </Typography>
                        </SeverityPill>
                    )}
                </Box> */}
            </CardContent>
        </Card>
    );
}
