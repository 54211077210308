import { Card, Box, Typography, Container } from "@mui/material";

import DashboardLayout from "../components/dashboard/DashboardLayout";
import InvoiceInfo from "../components/invoice/InvoiceInfo";
import { InvoiceData } from "../types/invoice";
import { generatePDF } from "../components/invoice/utils/payment";

import { motion } from "framer-motion";
import { cardVariant } from "../components/animation/AnimatedPageWrapper";

function Invoice() {
    const handleDownload = (formData: InvoiceData) => {
        console.log(formData);
        generatePDF(formData);
    };

    return (
        <DashboardLayout>
            <Box
                component="main"
                sx={{
                    pb: 3,
                    px: 3,
                    maxWidth: 1200,
                    mx: "auto",
                }}
            >
                <Typography variant="h3" py="12pt" color="white">
                    Invoice Maker
                </Typography>
                <Container component={motion.div} variants={cardVariant}>
                    <Card variant="outlined" sx={{ border: "none" }}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                mx: 5,
                                mt: 5,
                                mb: 3,
                            }}
                        >
                            <InvoiceInfo handleDownload={handleDownload} />
                        </Box>
                    </Card>
                </Container>
            </Box>
        </DashboardLayout>
    );
}

export default Invoice;
