import { useState } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { DashboardNavbar } from "./DashboardNavbar";
import { DashboardSidebar } from "./DashboardSidebar";
import AnimatedPageWrapper from "../animation/AnimatedPageWrapper";

const DashboardLayoutRoot = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    height: "100%",
    display: "flex",
    flex: "1 1 auto",
    maxWidth: "100%",
    paddingTop: 64,
    [theme.breakpoints.up("lg")]: {
        paddingLeft: 240,
    },
}));

export default function DashboardLayout(props: any) {
    const { children } = props;
    const [isSidebarOpen, toggleSidebarOpen] = useState(true);

    return (
        <>
            <DashboardLayoutRoot>
                <Box
                    sx={{
                        // ml: "40px",
                        // mr: "40px",
                        mx: "auto",
                        display: "flex",
                        flex: "1 1 auto",
                        flexDirection: "column",
                        width: "100%",
                    }}
                >
                    <AnimatedPageWrapper>{children}</AnimatedPageWrapper>
                </Box>
            </DashboardLayoutRoot>
            <DashboardNavbar
                onSidebarOpen={() => toggleSidebarOpen(!isSidebarOpen)}
            />
            <DashboardSidebar
                onClose={() => toggleSidebarOpen(!isSidebarOpen)}
                open={isSidebarOpen}
            />
        </>
    );
}
