// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import "firebase/compat/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCsfiYM1D_mJWXsTr5DOtIGxMVWo3Y5KmE",
    authDomain: "isotope-c39e2.firebaseapp.com",
    projectId: "isotope-c39e2",
    storageBucket: "isotope-c39e2.appspot.com",
    messagingSenderId: "150500536042",
    appId: "1:150500536042:web:5eba1b4f361647d66c3193",
    measurementId: "G-KV85PNLCW8",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = firebase.auth();
export const db = getFirestore(app);
export const functions = getFunctions(app);
const dev = false;
if (dev) {
    connectFunctionsEmulator(functions, "localhost", 5001);
}

export default app;
