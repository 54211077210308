import React, { Fragment, useEffect } from "react";
import { Delete } from "@mui/icons-material";
import {
    Box,
    Button,
    Typography,
    Dialog,
    Toolbar,
    ToggleButton,
    ToggleButtonGroup,
} from "@mui/material";
import { useAppSelector } from "../../redux/hooks";
import { deleteSelectedStudents } from "../../utils/roster";
import { Student } from "../../types/student";

type TeamType = "A" | "B";

type TableToolbarProps = {
    team: "A" | "B";
    setTeam: React.Dispatch<React.SetStateAction<"A" | "B">>;
    selected: Set<string>;
    setSelected: React.Dispatch<React.SetStateAction<Set<string>>>;
    num_teams: number;
    students: Student[];
};

export default function RosterTableToolbar(props: TableToolbarProps) {
    const { team, setTeam, selected, setSelected, students } = props;

    useEffect(() => {
        setSelected(new Set<string>());
    }, [team, setSelected]);

    const [openConfirm, setOpenConfirm] = React.useState(false);

    const handleDelete = () => {
        try {
            deleteSelectedStudents(
                students.filter((student) => {
                    return selected.has(student.id);
                })
            );
            setSelected(new Set<string>());
            setOpenConfirm(false);
        } catch (error) {}
    };

    return (
        <Fragment>
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                }}
            >
                <ToggleButtonGroup exclusive value={team} size="small">
                    {(props.num_teams === 2 ? ["A", "B"] : ["A"]).map((t) => (
                        <ToggleButton
                            value={t}
                            onClick={() => setTeam(t as TeamType)}
                            key={t}
                        >
                            <Typography
                                variant="body1"
                                align="left"
                                color="neutral.dark"
                                noWrap
                            >
                                {`Team ${t}`}
                            </Typography>
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
                <Box sx={{ flex: "1 1 100%" }} />
                {selected.size > 0 ? (
                    <Button
                        variant="contained"
                        startIcon={<Delete />}
                        color="error"
                        onClick={() => setOpenConfirm(true)}
                    >
                        <Typography variant="body1">Selected</Typography>
                    </Button>
                ) : null}
            </Toolbar>
            <Dialog
                open={openConfirm}
                onClose={() => setOpenConfirm(false)}
                fullWidth
            >
                <Box my={3} mx={3}>
                    <Typography variant="h5" mb={3}>
                        Delete Selected Student(s)
                    </Typography>
                    <Typography variant="body1">
                        Are you sure you want to delete {selected.size}{" "}
                        student(s)?
                    </Typography>
                    <Box
                        pt={3}
                        sx={{ display: "flex", flexDirection: "row-reverse" }}
                    >
                        <Button
                            variant="contained"
                            color="error"
                            onClick={handleDelete}
                        >
                            <Typography variant="body1">Delete</Typography>
                        </Button>
                        <Button onClick={() => setOpenConfirm(false)}>
                            <Typography
                                variant="body1"
                                color="neutral.secondary"
                            >
                                Cancel
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            </Dialog>
        </Fragment>
    );
}
