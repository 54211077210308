import React, { useState } from "react";
import PropTypes from "prop-types";
import AddIcon from "@mui/icons-material/Add";
import {
    Box,
    Button,
    Dialog,
    TextField,
    Typography,
    Grid,
    MenuItem,
} from "@mui/material";

import { useAppSelector } from "../../redux/hooks";
import { addStudent } from "../../utils/roster";
import { NewStudent, Student } from "../../types/student";

type AddStudentProps = {
    team: "A" | "B";
    students_in_team: Student[];
    school_id: string;
};

type TeamType = "A" | "B";

type AddStudentForm = {
    first: string;
    last: string;
    grade: number;
    alternate: boolean;
};

export default function AddStudent(props: AddStudentProps) {
    const team: TeamType = props.team;
    const [open, setOpen] = useState(false);

    const getFormData = (e: React.SyntheticEvent<HTMLFormElement>) => {
        const data = new FormData(e.currentTarget);
        return {
            first: String(data.get("first")).trim(),
            last: String(data.get("last")).trim(),
            grade: Number(data.get("grade")),
            alternate: Boolean(
                String(data.get("alternate")) === "yes" ? true : false
            ),
        };
    };

    const initialError = {
        first: "",
        last: "",
        grade: "",
        alternate: "",
    };

    const [formErrors, setFormErrors] = useState(initialError);

    const validateForm = (data: AddStudentForm) => {
        let result = true;
        let newErrors = { ...formErrors };
        if (data.first === "") {
            newErrors.first = "Please enter first name.";
            result = false;
        }
        if (data.last === "") {
            newErrors.last = "Please enter last name.";
            result = false;
        }
        if (data.grade === 0) {
            // 0 means grade wasn't input
            newErrors.grade = "Please enter grade.";
            result = false;
        }
        setFormErrors(newErrors);
        return result;
    };

    const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault();
        const data = getFormData(e);
        const new_student: NewStudent = {
            ...data,
            school: props.school_id,
            release_form: "",
            team: team,
        };
        if (validateForm(data)) {
            try {
                addStudent(new_student, props.students_in_team, setOpen);
            } catch (error) {
                console.log(error);
            }
        }
    };

    const handleClose = () => {
        setOpen(false);
        setFormErrors(initialError);
    };

    return (
        <>
            <Box sx={{ py: 2, display: "flex", justifyContent: "center" }}>
                <Button variant={"contained"} onClick={() => setOpen(true)}>
                    <AddIcon />
                    <Typography color="neutral.white" variant="h6" ml={1}>
                        Add Student
                    </Typography>
                </Button>
            </Box>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
                <Box
                    component="form"
                    noValidate
                    onSubmit={handleSubmit}
                    my={3}
                    mx={3}
                >
                    <Typography variant="h5" mb={3}>
                        Add Student
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={2}>
                            <TextField
                                label="Grade"
                                select
                                fullWidth
                                required
                                id="grade"
                                name="grade"
                                defaultValue={""}
                                helperText={formErrors.grade}
                                error={formErrors.grade !== ""}
                                FormHelperTextProps={{
                                    sx: { fontSize: 14 },
                                }}
                                onChange={(e) => {
                                    if (e.target.value !== "") {
                                        setFormErrors({
                                            ...formErrors,
                                            grade: "",
                                        });
                                    }
                                }}
                            >
                                <MenuItem value={9}>9</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={11}>11</MenuItem>
                                <MenuItem value={12}>12</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <TextField
                                label="Alternate"
                                select
                                fullWidth
                                required
                                id="alternate"
                                name="alternate"
                                helperText={formErrors.alternate}
                                error={formErrors.alternate !== ""}
                                FormHelperTextProps={{
                                    sx: { fontSize: 14 },
                                }}
                                defaultValue="no"
                            >
                                <MenuItem value={"no"}>No</MenuItem>
                                <MenuItem value={"yes"}>Yes</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                required
                                fullWidth
                                id="first"
                                label="First Name"
                                name="first"
                                type="text"
                                helperText={formErrors.first}
                                error={formErrors.first !== ""}
                                FormHelperTextProps={{
                                    sx: { fontSize: 14 },
                                }}
                                onChange={(e) => {
                                    if (e.target.value !== "") {
                                        setFormErrors({
                                            ...formErrors,
                                            first: "",
                                        });
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                required
                                fullWidth
                                id="last"
                                label="Last Name"
                                name="last"
                                type="text"
                                helperText={formErrors.last}
                                error={formErrors.last !== ""}
                                FormHelperTextProps={{
                                    sx: { fontSize: 14 },
                                }}
                                onChange={(e) => {
                                    if (e.target.value !== "") {
                                        setFormErrors({
                                            ...formErrors,
                                            last: "",
                                        });
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Box
                        pt={3}
                        sx={{
                            display: "flex",
                            flexDirection: "row-reverse",
                        }}
                    >
                        <Button variant="contained" type="submit" autoFocus>
                            <Typography variant="body1" color="neutral.white">
                                Add
                            </Typography>
                        </Button>
                        <Button onClick={handleClose}>
                            <Typography
                                variant="body1"
                                color="neutral.secondary"
                            >
                                Cancel
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            </Dialog>
        </>
    );
}
