import { AnyAction } from "redux";
import { User } from "../../types/users";
import { School } from "../../types/school";

type userState = {
    loading_user: boolean;
    user: User | null;
    loading_school: boolean;
    school: School | null;
};

const initialState: userState = {
    loading_user: false,
    user: null,
    loading_school: false,
    school: null,
    // school: {
    //     id: "",
    //     name: "",
    //     students: [],
    //     payment: "incomplete",
    //     team_number: ["C000"],
    //     self_schedule: {
    //         bridge: [-1],
    //         data_science: [-1],
    //         flight: [-1],
    //         game_theory: [-1],
    //         quantum_quandaries: [-1],
    //         scrambler: [-1],
    //         trajectory: [-1],
    //     },
    // },
};

export const LOAD_USER_REQUEST = "user/load_user_request";
export const LOAD_USER_SUCCESS = "user/load_user_success";
export const LOAD_USER_FAILURE = "user/load_user_failure";
export const CLEAR_USER_DATA = "user/clear_user_data";

export const LOAD_SCHOOL_REQUEST = "user/load_school_request";
export const LOAD_SCHOOL_SUCCESS = "user/load_school_success";
export const LOAD_SCHOOL_FAILURE = "user/load_school_failure";
export const CLEAR_SCHOOL_DATA = "user/clear_school_data";
export const LOGOUT = "user/logout";

export default function userReducer(state = initialState, action: AnyAction) {
    switch (action.type) {
        case CLEAR_USER_DATA: {
            return {
                ...state,
                loading_user: false,
                user: null,
            };
        }
        case LOAD_USER_REQUEST: {
            return {
                ...state,
                loading_user: true,
            };
        }
        case LOAD_USER_FAILURE: {
            return {
                ...state,
                user: null,
                loading_user: false,
            };
        }
        case LOAD_USER_SUCCESS: {
            return {
                ...state,
                loading_user: false,
                user: action.payload,
            };
        }
        case CLEAR_SCHOOL_DATA: {
            return {
                ...state,
                loading_school: false,
                school: null,
            };
        }
        case LOAD_SCHOOL_REQUEST: {
            return {
                ...state,
                loading_school: true,
            };
        }
        case LOAD_SCHOOL_FAILURE: {
            return {
                ...state,
                school: null,
                loading_school: false,
            };
        }
        case LOAD_SCHOOL_SUCCESS: {
            return {
                ...state,
                loading_school: false,
                school: action.payload,
            };
        }
        case LOGOUT: {
            return {
                ...initialState,
            };
        }
        default:
            return state;
    }
}
