import {
    SET_ERROR,
    CLEAR_ERROR,
    SET_SUCCESS,
    CLEAR_SUCCESS,
} from "./popupReducer";

export const setError = (err_msg: string) => {
    return {
        type: SET_ERROR,
        payload: err_msg,
    };
};

export const clearError = () => {
    return {
        type: CLEAR_ERROR,
    };
};

export const setSuccess = (success_msg: string) => {
    return {
        type: SET_SUCCESS,
        payload: success_msg,
    };
};

export const clearSuccess = () => {
    return {
        type: CLEAR_SUCCESS,
    };
};
