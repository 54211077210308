import React, { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { Box, Grid, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { cardVariant } from "../components/animation/AnimatedPageWrapper";

import DashboardLayout from "../components/dashboard/DashboardLayout";
import GenericCard from "../components/GenericCard";
import ProfileInfo from "../components/settings/ProfileInfo";
import { useAppSelector } from "../redux/hooks";
import { updateProfile } from "../utils/users";

function Settings() {
    const user = useContext(AuthContext);
    const userInfo = useAppSelector((state) => state.user.user);
    const schoolInfo = useAppSelector((state) => state.user.school);

    return (
        <>
            <DashboardLayout>
                <Box
                    component="main"
                    sx={{
                        pb: 3,
                        px: 3,
                    }}
                >
                    <Typography variant="h3" py="12pt" color="white">
                        Settings
                    </Typography>
                    <Grid container direction="column" alignItems="center">
                        <Grid container spacing={2}>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={6}
                                lg={5}
                                xl={4}
                                component={motion.div}
                                variants={cardVariant}
                            >
                                <GenericCard title="Profile">
                                    <ProfileInfo
                                        handleUpdate={updateProfile}
                                        userInfo={userInfo}
                                        schoolInfo={schoolInfo}
                                    />
                                </GenericCard>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </DashboardLayout>
        </>
    );
}

export default Settings;
