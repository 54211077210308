import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import Loading from "../../pages/Loading";
import { useAppSelector } from "../../redux/hooks";

import { Roles } from "../../types/roles";

type ProtectedProps = {
    children: React.ReactNode;
    roles: Array<Roles>;
};

function ProtectedRoute(props: ProtectedProps) {
    const user = useContext(AuthContext);
    const loading = useAppSelector((state) => state.auth.loading);
    const loading_user = useAppSelector((state) => state.user.loading_user);
    const loading_school = useAppSelector((state) => state.user.loading_school);

    const userInfo = useAppSelector((state) => state.user.user);

    if (loading || loading_user || loading_school) {
        return <Loading />;
    } else {
        const permitted = user && props.roles.includes(userInfo.role as Roles);
        return permitted ? (
            (props.children as JSX.Element)
        ) : user ? (
            <Navigate to="/" />
        ) : (
            <Navigate to="/login" />
        );
    }
}

export default ProtectedRoute;
