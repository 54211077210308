export const tournament_date = "January 20, 2024";
export const tournament_guide_link =
    "https://docs.google.com/document/d/1QOm06posXMW83XFRjUXLlzDKiEMJIFBdE8fSk7X-YgY/edit?usp=sharing";
export const tournament_rule = "Nationals";
export const tournament_format = "In Person";
export const tournament_countdown = "20 Jan 2024 07:30:00 EST";
export const tournament_schedule_link =
    "https://scioly.mit.edu/docs/2024_events_schedule.pdf";

export const release_form_deadline = "January 12, 2024";

// Schedule Related
export const num_teams_per_slot: number = 12;

export const slot_times: Array<string> = [
    "7:30 - 8:30", // Impound
    "8:45 - 9:45", // Slot 1
    "10:00 - 11:00", // Slot 2
    "11:15 - 12:15", // Slot 3
    "12:30 - 1:30", // Slot 4
    "1:45 - 2:45", // Slot 5
    "3:00 - 4:00", // Slot 6
];
export const events: Array<{
    id: number;
    location: string;
    event: string;
    color: string;
    impound: boolean;
    selfschedule: boolean;
    label: string;
}> = [
    {
        id: 1,
        event: "Anatomy & Physiology",
        location: "TBD",
        label: "anatomy_and_physiology",
        color: "Group2",
        impound: false,
        selfschedule: false,
    },
    {
        id: 2,
        event: "Astronomy",
        location: "TBD",
        label: "astronomy",
        color: "Group2",
        impound: false,
        selfschedule: false,
    },
    {
        id: 3,
        event: "Optics",
        location: "TBD",
        label: "optics",
        color: "Group2",
        impound: false,
        selfschedule: false,
    },
    {
        id: 4,
        event: "Chem Lab",
        location: "TBD",
        label: "chem_lab",
        color: "Group4",
        impound: false,
        selfschedule: false,
    },
    {
        id: 5,
        event: "Codebusters",
        location: "TBD",
        label: "codebusters",
        color: "Group4",
        impound: false,
        selfschedule: false,
    },
    {
        id: 6,
        event: "Ecology",
        location: "TBD",
        label: "ecology",
        color: "Group4",
        impound: false,
        selfschedule: false,
    },
    {
        id: 7,
        event: "Detector Building",
        location: "TBD",
        label: "detector_building",
        color: "Group6",
        impound: false,
        selfschedule: false,
    },
    {
        id: 8,
        event: "Fossils",
        location: "TBD",
        label: "fossils",
        color: "Group6",
        impound: false,
        selfschedule: false,
    },
    {
        id: 9,
        event: "Write It Do It",
        location: "TBD",
        label: "write_it_do_it",
        color: "Group6",
        impound: false,
        selfschedule: false,
    },
    {
        id: 10,
        event: "Disease Detectives",
        location: "TBD",
        label: "disease_detectives",
        color: "Group1",
        impound: false,
        selfschedule: false,
    },
    {
        id: 11,
        event: "Fermi Questions",
        location: "TBD",
        label: "fermi_questions",
        color: "Group1",
        impound: false,
        selfschedule: false,
    },
    {
        id: 12,
        event: "Forensics",
        location: "TBD",
        label: "forensics",
        color: "Group1",
        impound: false,
        selfschedule: false,
    },
    {
        id: 13,
        event: "Dynamic Planet",
        location: "TBD",
        label: "dynamic_planet",
        color: "Group3",
        impound: false,
        selfschedule: false,
    },
    {
        id: 14,
        event: "Forestry",
        location: "TBD",
        label: "forestry",
        color: "Group3",
        impound: false,
        selfschedule: false,
    },
    {
        id: 15,
        event: "Wind Power",
        location: "TBD",
        label: "wind_power",
        color: "Group3",
        impound: false,
        selfschedule: false,
    },
    {
        id: 16,
        event: "Experimental Design",
        location: "TBD",
        label: "experimental_design",
        color: "Group5",
        impound: false,
        selfschedule: false,
    },
    {
        id: 17,
        event: "Geologic Mapping",
        location: "TBD",
        label: "geologic_mapping",
        color: "Group5",
        impound: false,
        selfschedule: false,
    },
    {
        id: 18,
        event: "Microbe Mission",
        location: "TBD",
        label: "microbe_mission",
        color: "Group5",
        impound: false,
        selfschedule: false,
    },
    {
        id: 19,
        event: "Air Trajectory",
        location: "TBD",
        label: "air_trajectory",
        color: "Group7",
        impound: true,
        selfschedule: true,
    },
    {
        id: 20,
        event: "Flight",
        location: "TBD",
        label: "flight",
        color: "Group7",
        impound: false,
        selfschedule: true,
    },
    {
        id: 21,
        event: "Robot Tour",
        location: "TBD",
        label: "robot_tour",
        color: "Group7",
        impound: true,
        selfschedule: true,
    },
    {
        id: 22,
        event: "Scrambler",
        location: "TBD",
        label: "scrambler",
        color: "Group7",
        impound: true,
        selfschedule: true,
    },
    {
        id: 23,
        event: "Tower",
        location: "TBD",
        label: "tower",
        color: "Group7",
        impound: false,
        selfschedule: true,
    },
    {
        id: 24,
        event: "Figure That (Trial)",
        location: "TBD",
        label: "figure_that",
        color: "Group8",
        impound: false,
        selfschedule: true,
    },
    {
        id: 25,
        event: "Pokémon Pset (Trial)",
        location: "TBD",
        label: "pokemon_pset",
        color: "Group8",
        impound: false,
        selfschedule: true,
    },
    {
        id: 26,
        event: "Tournament Tycoon (Trial)",
        location: "TBD",
        label: "tournament_tycoon",
        color: "Group8",
        impound: false,
        selfschedule: true,
    },
];

// Self Schedule Related
export const max_num_teams_per_self_schedule_slot: number = 13;
export const self_schedule_open = "12 Jan 2024 15:00:00 EST";
export const self_schedule_open_message = "3 PM EST on January 12th, 2024";
interface Slot {
    slot: string;
    time: string;
}
export const self_schedule_slots: Slot[] = [
    { slot: "Slot 1", time: "8:45 AM - 9:45 AM" },
    { slot: "Slot 2", time: "10:00 AM - 11:00 AM" },
    { slot: "Slot 3", time: "11:15 AM - 12:15 PM" },
    { slot: "Slot 4", time: "12:30 PM - 1:30 PM" },
    { slot: "Slot 5", time: "1:45 PM - 2:45 PM" },
    { slot: "Slot 6", time: "3:00 PM - 4:00 PM" },
];

export interface SelfScheduleEventSlotCaps {
    [key: string]: number[];
}

export const self_schedule_events = events.filter((e) => e.selfschedule);

const default_single_event_capacities = new Array(6).fill(
    max_num_teams_per_self_schedule_slot
);

export const default_all_events_capacities = self_schedule_events.reduce(
    (obj, item) => {
        obj[item["label"]] = default_single_event_capacities;
        return obj;
    },
    {} as SelfScheduleEventSlotCaps
);
