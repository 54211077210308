import { Box, Button, Grid, TextField, Select, MenuItem } from "@mui/material";
import React, { SetStateAction, useEffect } from "react";
import SeverityPill from "../SeverityPill";

import { RegistrationData } from "../../types/authentication";
import { User } from "../../types/users";
import { School } from "../../types/school";

type ProfileInfoProp = {
    handleUpdate: (
        formData: User,
        setChanged: React.Dispatch<SetStateAction<boolean>>
    ) => void;
    userInfo: User;
    schoolInfo: School;
};

function ProfileInfo(props: ProfileInfoProp) {
    const [email, setEmail] = React.useState<string>(props.userInfo.email);
    const [first, setFirst] = React.useState<string>(props.userInfo.first_name);
    const [last, setLast] = React.useState<string>(props.userInfo.last_name);
    const [phone, setPhone] = React.useState<string>(
        props.userInfo.phone_number
    );
    const [school, setSchool] = React.useState<string>(props.schoolInfo.name);
    const [role, setRole] = React.useState<string>(props.userInfo.role);
    const [changed, setChanged] = React.useState(false);

    const handlePhoneChange = (e: any) => {
        const onlyNums = e.target.value.replace(/[^0-9]/g, "");
        if (onlyNums.length < 4) {
            setPhone(onlyNums);
        } else if (onlyNums.length < 7) {
            const number = onlyNums.replace(/(\d{3})(\d{0,})/, "($1) $2");
            setPhone(number);
        } else if (onlyNums.length <= 10) {
            const number = onlyNums.replace(
                /(\d{3})(\d{3})(\d{0,})/,
                "($1) $2-$3"
            );
            setPhone(number);
        }
        if (e.target.value !== "") {
            setFormErrors({
                ...formErrors,
                phone_number: "",
            });
        }
    };

    const getFormData = (e: React.SyntheticEvent<HTMLFormElement>) => {
        const data = new FormData(e.currentTarget);
        return {
            first_name: String(data.get("first_name")).trim(),
            last_name: String(data.get("last_name")).trim(),
            phone_number: String(data.get("phone_number")),
        };
    };

    const initialError = {
        first_name: "",
        last_name: "",
        phone_number: "",
    };
    const [formErrors, setFormErrors] = React.useState(initialError);

    const validateForm = (data: User) => {
        let result = true;
        let newErrors = { ...formErrors };
        if (data.first_name === "") {
            newErrors.first_name = "Cannot be empty.";
            result = false;
        }
        if (data.last_name === "") {
            newErrors.last_name = "Cannot be empty.";
            result = false;
        }
        if (
            data.phone_number === "" ||
            !data.phone_number.match(/\(\d{3}\) \d{3}-\d{4}/)
        ) {
            newErrors.phone_number = "Please enter a valid phone number.";
            result = false;
        }
        setFormErrors(newErrors);
        return result;
    };

    const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault();
        const data = { ...getFormData(e), id: props.userInfo.id } as User;
        if (validateForm(data)) {
            props.handleUpdate(data, setChanged);
        }
    };

    useEffect(() => {
        first !== props.userInfo.first_name ||
        last !== props.userInfo.last_name ||
        phone !== props.userInfo.phone_number
            ? setChanged(true)
            : setChanged(false);
    }, [first, last, phone]);

    return (
        <>
            <Box
                component="form"
                noValidate
                maxWidth="md"
                onSubmit={handleSubmit}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            id="first_name"
                            label="First Name"
                            name="first_name"
                            type="text"
                            value={first}
                            helperText={formErrors.first_name}
                            error={formErrors.first_name !== ""}
                            FormHelperTextProps={{
                                sx: { fontSize: 14 },
                            }}
                            onChange={(event) => {
                                setFirst(event.target.value);
                                if (event.target.value !== "") {
                                    setFormErrors({
                                        ...formErrors,
                                        first_name: "",
                                    });
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            id="last_name"
                            label="Last Name"
                            name="last_name"
                            type="text"
                            value={last}
                            helperText={formErrors.last_name}
                            error={formErrors.last_name !== ""}
                            FormHelperTextProps={{
                                sx: { fontSize: 14 },
                            }}
                            onChange={(event) => {
                                setLast(event.target.value);
                                if (event.target.value !== "") {
                                    setFormErrors({
                                        ...formErrors,
                                        last_name: "",
                                    });
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            type="email"
                            value={email}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            id="phone_number"
                            label="Phone Number"
                            name="phone_number"
                            type="text"
                            value={phone}
                            helperText={formErrors.phone_number}
                            error={formErrors.phone_number !== ""}
                            FormHelperTextProps={{
                                sx: { fontSize: 14 },
                            }}
                            onChange={handlePhoneChange}
                        />
                    </Grid>
                    <Grid item sm={4} xs={6}>
                        <TextField
                            id="role"
                            label="Role"
                            name="role"
                            type="text"
                            select
                            value={role}
                            disabled
                            fullWidth
                        >
                            <MenuItem value={"admin"}>
                                <SeverityPill color="primary">
                                    admin
                                </SeverityPill>
                            </MenuItem>
                            <MenuItem value={"coach"}>
                                <SeverityPill color="secondary">
                                    coach
                                </SeverityPill>
                            </MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item sm={8} xs={12}>
                        <TextField
                            required
                            fullWidth
                            id="school"
                            label="School"
                            name="school"
                            type="text"
                            value={school}
                            disabled
                        />
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3, fontSize: 16 }}
                        fullWidth
                        disabled={!changed}
                    >
                        Save
                    </Button>
                </Grid>

                {/* <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3, fontSize: 16 }}
                        fullWidth
                    >
                        Register
                    </Button>
                </Box> */}
            </Box>
        </>
    );
}

export default ProfileInfo;
