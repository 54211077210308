import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Grid,
    MenuItem,
    TextField,
    Typography,
    InputAdornment,
} from "@mui/material";

import { InvoiceData } from "../../types/invoice";

type InvoiceProp = {
    handleDownload: (formData: InvoiceData) => void;
};

function InvoiceInfo(props: InvoiceProp) {
    const getFormData = (e: React.SyntheticEvent<HTMLFormElement>) => {
        const data = new FormData(e.currentTarget);
        return {
            date: String(data.get("date")),
            due_date: String(data.get("due_date")),
            invoice_num: String(data.get("invoice_num")),

            school_name: String(data.get("school_name")).trim(),
            school_address: String(data.get("school_address")).trim(),

            recipient_name: String(data.get("recipient_name")).trim(),
            recipient_address: String(data.get("recipient_address")).trim(),

            num_teams: Number(data.get("num_teams")),
            fee_per_team: Number(data.get("fee_per_team")),
        };
    };

    const defaultInvoice = {
        date: new Date().toISOString().slice(0, 10),
        due_date: "2023-12-01",
        recipient_name: "Science Olympiad at MIT",
        recipient_address: "P.O. Box 397105\nCambridge, MA\n02139-7136",
        num_teams: 1,
        fee_per_team: 200,
    };

    const initialError = {
        date: "",
        due_date: "",
        invoice_num: "",
        school_name: "",
        recipient_name: "",
        recipient_address: "",
        num_teams: "",
        fee_per_team: "",
    };
    const [formErrors, setFormErrors] = useState(initialError);

    const validateForm = (data: InvoiceData) => {
        let result = true;
        let newErrors = { ...formErrors };
        if (data.date === "" || !data.date.match(/^\d{4}-\d{2}-\d{2}$/)) {
            newErrors.date = "Please enter an invoice date.";
            result = false;
        }
        if (
            data.due_date === "" ||
            !data.due_date.match(/^\d{4}-\d{2}-\d{2}$/)
        ) {
            newErrors.due_date = "Please enter a due date.";
            result = false;
        }
        if (
            data.invoice_num === "" ||
            !data.invoice_num.match(/^[1-9]\d{0,4}$/)
        ) {
            newErrors.invoice_num =
                "Please enter an invoice number in integer.";
            result = false;
        }

        if (data.school_name === "") {
            newErrors.school_name = "Please enter a school name.";
            result = false;
        }
        if (data.recipient_name === "") {
            newErrors.recipient_name = "Please enter a recipient name.";
            result = false;
        }
        if (data.recipient_address === "") {
            newErrors.recipient_address = "Please enter a recipient address.";
            result = false;
        }

        if (data.fee_per_team === 0 || Number.isNaN(data.fee_per_team)) {
            newErrors.fee_per_team = "Please enter a valid amount.";
            result = false;
        }

        setFormErrors(newErrors);
        return result;
    };

    const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault();
        const data: InvoiceData = getFormData(e);
        if (validateForm(data)) {
            props.handleDownload(data);
        }
    };

    return (
        <>
            <Box component="form" onSubmit={handleSubmit} noValidate>
                <Grid container spacing={6}>
                    {/* Parent Grid */}
                    <Grid container item xs={12} spacing={2}>
                        {/* Top Part: date, invoice number, due date */}
                        <Grid item xs={12} sm={4}>
                            <TextField
                                name="invoice_num"
                                required
                                fullWidth
                                id="invoice_num"
                                label="Invoice #"
                                helperText={formErrors.invoice_num}
                                error={formErrors.invoice_num !== ""}
                                FormHelperTextProps={{
                                    sx: { fontSize: 12 },
                                }}
                                onChange={(e) => {
                                    if (e.target.value !== "") {
                                        setFormErrors({
                                            ...formErrors,
                                            invoice_num: "",
                                        });
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                name="date"
                                required
                                fullWidth
                                id="date"
                                label="Date"
                                defaultValue={defaultInvoice.date}
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                // helperText={formErrors.date}
                                error={formErrors.date !== ""}
                                FormHelperTextProps={{
                                    sx: { fontSize: 12 },
                                }}
                                onChange={(e) => {
                                    if (e.target.value !== "") {
                                        setFormErrors({
                                            ...formErrors,
                                            date: "",
                                        });
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                name="due_date"
                                required
                                fullWidth
                                id="due_date"
                                label="Due Date"
                                defaultValue={defaultInvoice.due_date}
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                // helperText={formErrors.due_date}
                                error={formErrors.due_date !== ""}
                                FormHelperTextProps={{
                                    sx: { fontSize: 12 },
                                }}
                                onChange={(e) => {
                                    if (e.target.value !== "") {
                                        setFormErrors({
                                            ...formErrors,
                                            due_date: "",
                                        });
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={2}>
                        {/* Middle Part: Bill To, Ship To */}
                        <Grid container item xs={12} sm={6} spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6">Bill To</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="school_name"
                                    label="School Name"
                                    name="school_name"
                                    type="text"
                                    // helperText={formErrors.school_name}
                                    error={formErrors.school_name !== ""}
                                    FormHelperTextProps={{
                                        sx: { fontSize: 12 },
                                    }}
                                    onChange={(e) => {
                                        if (e.target.value !== "") {
                                            setFormErrors({
                                                ...formErrors,
                                                school_name: "",
                                            });
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="School Address (Optional)"
                                    id="school_address"
                                    name="school_address"
                                    fullWidth
                                    multiline
                                    rows={4}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} sm={6} spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6">Ship To</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Recipient Name"
                                    required
                                    id="recipient_name"
                                    name="recipient_name"
                                    defaultValue={defaultInvoice.recipient_name}
                                    fullWidth
                                    // helperText={formErrors.recipient_name}
                                    error={formErrors.recipient_name !== ""}
                                    FormHelperTextProps={{
                                        sx: { fontSize: 12 },
                                    }}
                                    onChange={(e) => {
                                        if (e.target.value !== "") {
                                            setFormErrors({
                                                ...formErrors,
                                                recipient_name: "",
                                            });
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Recipient Address"
                                    required
                                    id="recipient_address"
                                    name="recipient_address"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    defaultValue={
                                        defaultInvoice.recipient_address
                                    }
                                    // helperText={formErrors.recipient_address}
                                    error={formErrors.recipient_address !== ""}
                                    FormHelperTextProps={{
                                        sx: { fontSize: 12 },
                                    }}
                                    onChange={(e) => {
                                        if (e.target.value !== "") {
                                            setFormErrors({
                                                ...formErrors,
                                                recipient_address: "",
                                            });
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={2}>
                        {/* Bottom Part: Num of teams, Reg. Fee per team */}
                        <Grid item xs={3} sm={2}>
                            <TextField
                                name="num_teams"
                                required
                                fullWidth
                                id="num_teams"
                                label="# of Teams"
                                select
                                defaultValue={defaultInvoice.num_teams}
                            >
                                <MenuItem key={1} value={1}>
                                    1
                                </MenuItem>
                                <MenuItem key={2} value={2}>
                                    2
                                </MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={4} sm={3}>
                            <TextField
                                name="fee_per_team"
                                required
                                fullWidth
                                id="fee_per_team"
                                label="Reg. Fee (per team)"
                                defaultValue={defaultInvoice.fee_per_team}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            $
                                        </InputAdornment>
                                    ),
                                }}
                                helperText={formErrors.fee_per_team}
                                error={formErrors.fee_per_team !== ""}
                                FormHelperTextProps={{
                                    sx: { fontSize: 12 },
                                }}
                                onChange={(e) => {
                                    if (e.target.value !== "") {
                                        setFormErrors({
                                            ...formErrors,
                                            fee_per_team: "",
                                        });
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        mt: 2,
                    }}
                >
                    <Button
                        type="submit"
                        variant="contained"
                        sx={{ mt: 3 }}
                        fullWidth
                    >
                        <Typography color={"neutral.white"}>
                            Generate Invoice
                        </Typography>
                    </Button>
                </Box>
            </Box>
        </>
    );
}

export default InvoiceInfo;
