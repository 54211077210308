import React, { useContext, useState, useEffect } from "react";

import { Box, Typography } from "@mui/material";
import { Container } from "@mui/system";
import DashboardLayout from "../components/dashboard/DashboardLayout";
import SchoolsTable from "../components/schools/SchoolsTable";
import { motion } from "framer-motion";
import { cardVariant } from "../components/animation/AnimatedPageWrapper";

import { School } from "../types/school";
import { collection, query, orderBy, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";

export default function Schools() {
    const [schools, setSchools] = useState<School[]>([]);

    useEffect(() => {
        const schoolsQuery = query(collection(db, "schools"), orderBy("name"));

        onSnapshot(schoolsQuery, (querySnapshot) => {
            setSchools(
                querySnapshot.docs.map(
                    (doc) =>
                        ({
                            id: doc.id,
                            ...doc.data(),
                        } as School)
                )
            );
        });
    }, []);

    return (
        <>
            <DashboardLayout>
                <Box
                    component="main"
                    sx={{
                        pb: 3,
                        px: 3,
                        maxWidth: 1200,
                        mx: "auto",
                    }}
                >
                    <Typography variant="h3" py="12pt" color="white">
                        Schools
                    </Typography>
                    <Container component={motion.div} variants={cardVariant}>
                        <Box>
                            <SchoolsTable schools={schools} />
                        </Box>
                    </Container>
                </Box>
            </DashboardLayout>
        </>
    );
}
