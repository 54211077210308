import * as React from "react";

import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Select,
    MenuItem,
    SelectChangeEvent,
} from "@mui/material";
import Paper from "@mui/material/Paper";

import UsersTableHead from "./UsersTableHead";
import SeverityPill from "../SeverityPill";

import { getComparator } from "./utils";
import { Order } from "./utils";

import { User } from "../../types/users";
import { School } from "../../types/school";
import { formatPhoneNumber, updateUserRole } from "../../utils/users";

type UsersTableProps = {
    users: User[];
    schools: School[];
};

export default function UsersTable(props: UsersTableProps) {
    const [order, setOrder] = React.useState<Order>("asc");
    const [orderBy, setOrderBy] = React.useState<keyof User>("first_name");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof User
    ) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSelectChange = (event: SelectChangeEvent, user_id: string) => {
        updateUserRole(user_id, event.target.value);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0
            ? Math.max(0, (1 + page) * rowsPerPage - props.users.length)
            : 0;

    return (
        <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
                <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={"small"}
                    >
                        <UsersTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={props.users.length}
                        />
                        <TableBody>
                            {props.users
                                .slice()
                                .sort(getComparator(order, orderBy))
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((u, index) => {
                                    const labelId = `user-table-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={u.first_name}
                                        >
                                            <TableCell align="left">
                                                <Select
                                                    size="small"
                                                    value={u.role}
                                                    onChange={(event) => {
                                                        handleSelectChange(
                                                            event,
                                                            u.id
                                                        );
                                                    }}
                                                    sx={{ width: "110px" }}
                                                >
                                                    <MenuItem value={"admin"}>
                                                        <SeverityPill color="primary">
                                                            admin
                                                        </SeverityPill>
                                                    </MenuItem>
                                                    <MenuItem value={"coach"}>
                                                        <SeverityPill color="secondary">
                                                            coach
                                                        </SeverityPill>
                                                    </MenuItem>
                                                </Select>
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                            >
                                                {u.first_name}
                                            </TableCell>
                                            <TableCell align="left">
                                                {u.last_name}
                                            </TableCell>
                                            <TableCell align="left">
                                                {u.email}
                                            </TableCell>
                                            <TableCell align="left">
                                                {u.phone_number}
                                            </TableCell>
                                            <TableCell align="left">
                                                {
                                                    props.schools.find(
                                                        (e) => e.id === u.school
                                                    )?.name
                                                }
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: 33 * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={props.users.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>
            </Paper>
        </Box>
    );
}
