import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT,
} from "./authReducer";
import firebase from "firebase/compat/app";

export const loginRequest = () => {
    return {
        type: LOGIN_REQUEST,
    };
};

export const loginSuccess = (user: firebase.User) => {
    return {
        type: LOGIN_SUCCESS,
        payload: { email: user.email, uid: user.uid },
    };
};

export const loginFailure = () => {
    return {
        type: LOGIN_FAILURE,
    };
};

export const logout_auth = () => {
    return {
        type: LOGOUT,
    };
};
