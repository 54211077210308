import React, { useContext } from "react";
import { Box, Grid, Typography, Container } from "@mui/material";
import { motion } from "framer-motion";
import { cardVariant } from "../components/animation/AnimatedPageWrapper";

import { AuthContext } from "../contexts/AuthContext";
import DashboardLayout from "../components/dashboard/DashboardLayout";
import { useAppSelector } from "../redux/hooks";

import Payment from "../components/dashboard/cards/Payment";
import TeamInfo from "../components/dashboard/cards/TeamInfo";
import Schedule from "../components/dashboard/cards/Schedule";
import TournamentInfo from "../components/dashboard/cards/TournamentInfo";
import ReleaseForm from "../components/dashboard/cards/ReleaseForm";
import Countdown from "../components/dashboard/cards/Countdown";

function Home() {
    const user = useContext(AuthContext);
    const first_name = useAppSelector((state) => state.user.user.first_name);
    const role = useAppSelector((state) => state.user.user.role);

    return (
        <DashboardLayout>
            <Box
                component="main"
                sx={{
                    pb: 3,
                    px: 1,
                }}
            >
                <Container maxWidth="xl">
                    {role === "admin" ? (
                        <Typography variant="h3" py="12pt" color="white">
                            Welcome, {first_name} (Admin)!
                        </Typography>
                    ) : (
                        <Typography variant="h3" py="12pt" color="white">
                            Welcome, {first_name}!
                        </Typography>
                    )}
                    <Grid container direction="column" alignItems="center">
                        <Grid container spacing={3}>
                            {role === "coach" ? (
                                <Grid
                                    item
                                    xl={3}
                                    lg={4}
                                    sm={6}
                                    xs={12}
                                    component={motion.div}
                                    variants={cardVariant}
                                >
                                    <TeamInfo />
                                </Grid>
                            ) : null}
                            <Grid
                                item
                                xl={3}
                                lg={4}
                                sm={6}
                                xs={12}
                                component={motion.div}
                                variants={cardVariant}
                            >
                                <TournamentInfo />
                            </Grid>
                            {role === "coach" ? (
                                <Grid
                                    item
                                    xl={3}
                                    lg={4}
                                    sm={6}
                                    xs={12}
                                    component={motion.div}
                                    variants={cardVariant}
                                >
                                    <ReleaseForm />
                                </Grid>
                            ) : null}
                            <Grid
                                item
                                xl={3}
                                lg={4}
                                sm={6}
                                xs={12}
                                component={motion.div}
                                variants={cardVariant}
                            >
                                <Countdown />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                component={motion.div}
                                variants={cardVariant}
                            >
                                <Schedule />
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </DashboardLayout>
    );
}

export default Home;
