// import React, { useContext } from "react";
import { Box, Container, Typography } from "@mui/material";
import SelfScheduleTable from "../components/selfschedule/SelfScheduleTable";
import { motion } from "framer-motion";
import { cardVariant } from "../components/animation/AnimatedPageWrapper";

// import { AuthContext } from "../contexts/AuthContext";
import DashboardLayout from "../components/dashboard/DashboardLayout";
import TimedRelease from "../components/timedrelease/TimedRelease";

export default function Schedule() {
    return (
        <DashboardLayout>
            <TimedRelease>
                <Box
                    component="main"
                    sx={{
                        pb: 3,
                        px: 3,
                        maxWidth: 1200,
                        mx: "auto",
                    }}
                >
                    <Typography variant="h3" py="12pt" color="white">
                        Self Schedule
                    </Typography>
                    <Container component={motion.div} variants={cardVariant}>
                        <Box>
                            <SelfScheduleTable />
                        </Box>
                    </Container>
                </Box>
            </TimedRelease>
        </DashboardLayout>
    );
}
