import React from "react";
import { Typography, Backdrop, Container, Box } from "@mui/material";
import { useAppSelector } from "../../redux/hooks";
import {
    self_schedule_open,
    self_schedule_open_message,
} from "../../utils/settings";

type ProtectedProps = {
    children: React.ReactNode;
};

function TimedRelease(props: ProtectedProps) {
    const userRole = useAppSelector((state) => state.user.user.role);

    const reveal_date = Date.parse(self_schedule_open);
    const reveal = reveal_date < Date.now() || userRole === "admin";

    if (reveal) {
        return props.children as JSX.Element;
    } else {
        return (
            <Box
                sx={{
                    px: 3,
                    mx: "auto",
                    display: "flex",
                    justifyContent: "space-evenly",
                }}
            >
                <Typography
                    variant="h4"
                    pt="20%"
                    mx="auto"
                    fontSize="28pt"
                    align="center"
                >
                    Self schedule will open at {self_schedule_open_message}.
                </Typography>
            </Box>
        );
    }
}

export default TimedRelease;
