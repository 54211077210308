import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import logo from "../../assets/scioly_logo.png";
import { Box, Typography } from "@mui/material";

export default function AnimatedTitle() {
    const float_in = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
        },
        end: { opacity: 0, y: -20 },
    };

    const full_title = (
        <Box
            component={motion.div}
            sx={{
                height: 200,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
            }}
            initial="hidden"
            animate="visible"
            variants={float_in}
            transition={{ delay: 0.5, duration: 1 }}
            exit={{ opacity: 0, y: -20 }}
            key="full_title"
        >
            <Typography align="center" component="div">
                <Typography display="inline" variant="h4" fontSize={"28pt"}>
                    I
                </Typography>
                <Typography display="inline" variant="h3" fontSize={"20pt"}>
                    ntegrated{" "}
                </Typography>
                <Typography display="inline" variant="h4" fontSize={"28pt"}>
                    S
                </Typography>
                <Typography display="inline" variant="h3" fontSize={"20pt"}>
                    cience
                    {"\u00a0"}
                </Typography>
                <Typography display="inline" variant="h4" fontSize={"28pt"}>
                    O
                </Typography>
                <Typography display="inline" variant="h3" fontSize={"20pt"}>
                    lympiad
                </Typography>
            </Typography>
            <Typography align="center" component="div">
                <Typography display="inline" variant="h4" fontSize={"28pt"}>
                    TO
                </Typography>
                <Typography display="inline" variant="h3" fontSize={"20pt"}>
                    urnament
                    {"\u00a0"}
                </Typography>
                <Typography display="inline" variant="h4" fontSize={"28pt"}>
                    P
                </Typography>
                <Typography display="inline" variant="h3" fontSize={"20pt"}>
                    lanning{" "}
                </Typography>
                <Typography display="inline" variant="h4" fontSize={"28pt"}>
                    E
                </Typography>
                <Typography display="inline" variant="h3" fontSize={"20pt"}>
                    nvironment
                </Typography>
            </Typography>
        </Box>
    );
    const short_title = (
        <motion.div
            initial="hidden"
            animate="visible"
            variants={float_in}
            transition={{ delay: 0, duration: 1 }}
        >
            <Typography variant="h4" fontSize={"28pt"}>
                ISOTOPE
            </Typography>
        </motion.div>
    );

    const [showFullTitle, setShowFullTitle] = useState(0);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowFullTitle(1);
            const timer2 = setTimeout(() => setShowFullTitle(2), 1500);
            return () => clearTimeout(timer2);
        }, 2500);
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    mt: 2,
                    mx: "auto",
                    width: "160px",
                    "& img": {
                        width: "100%",
                    },
                }}
                component={motion.div}
                initial="hidden"
                animate="visible"
                variants={float_in}
                transition={{ delay: 0, duration: 1 }}
            >
                <img src={logo} alt="MIT Science Olympiad Logo" />
            </Box>
            <Box
                component={motion.div}
                initial={{ height: 200 }}
                animate={{ height: 51 }}
                transition={{ delay: 4, duration: 1 }}
            >
                <AnimatePresence>
                    {showFullTitle === 0
                        ? full_title
                        : showFullTitle === 1
                        ? null
                        : short_title}
                </AnimatePresence>
            </Box>
        </>
    );
}
