import { Box, Button, ListItem } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

type DashboardNavItemProps = {
    href: string;
    icon: React.ReactNode;
    title: string;
    onClose: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function DashboardNavItem(props: DashboardNavItemProps) {
    const { href, icon, title } = props;
    const navigate = useNavigate();
    const location = useLocation();
    const active = href ? location.pathname === href : false;

    return (
        <ListItem
            disableGutters
            sx={{
                display: "flex",
                mb: 0.5,
                py: 0,
                px: 2,
            }}
        >
            <Button
                component="a"
                startIcon={icon}
                disableRipple
                sx={{
                    borderRadius: 1,
                    color: "neutral.300",
                    justifyContent: "flex-start",
                    //pl: `${paddingLeft}px`,
                    pr: 3,
                    textAlign: "left",
                    textTransform: "none",
                    width: "100%",
                    ...(active && {
                        backgroundColor: "rgba(255,255,255, 0.08)",
                        color: "secondary.main",
                        fontWeight: "fontWeightBold",
                    }),
                    "& .MuiButton-startIcon": {
                        color: active ? "secondary.main" : "neutral.400",
                    },
                    "&:hover": {
                        backgroundColor: "rgba(255,255,255, 0.08)",
                    },
                }}
                onClick={() => {
                    navigate(href);
                    props.onClose(false);
                }}
            >
                <Box sx={{ flexGrow: 1 }}>{title}</Box>
            </Button>
        </ListItem>
    );
}
