import { AnyAction } from "redux";
import firebase from "firebase/compat/app";

type authState = {
    loggedIn: boolean;
    loading: boolean;
    email: string | null;
    uid: string | null;
};

const initialState: authState = {
    loggedIn: false,
    loading: false,
    email: null,
    uid: null,
};

export const LOGIN_REQUEST = "auth/login_request";
export const LOGIN_SUCCESS = "auth/login_success";
export const LOGIN_FAILURE = "auth/login_failure";
export const LOGOUT = "auth/logout";

export default function authReducer(state = initialState, action: AnyAction) {
    switch (action.type) {
        case LOGIN_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case LOGIN_SUCCESS: {
            return {
                ...state,
                loggedIn: true,
                email: action.payload.email,
                uid: action.payload.uid,
                loading: false,
            };
        }
        case LOGIN_FAILURE: {
            return {
                ...state,
                loggedIn: false,
                loading: false,
            };
        }
        case LOGOUT: {
            return {
                ...initialState,
            };
        }
        default:
            return state;
    }
}
