import * as React from "react";

import {
    Box,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";

import { Order, headCells } from "./utils";
import { School } from "../../types/school";

export type SchoolsTableHeadProps = {
    onRequestSort: (
        event: React.MouseEvent<unknown>,
        property: keyof School
    ) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
};

export default function SchoolsTableHead(props: SchoolsTableHeadProps) {
    const { order, orderBy, rowCount, onRequestSort } = props;
    const createSortHandler =
        (property: keyof School) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell, i) =>
                    headCell.sortable ? (
                        <TableCell
                            key={i}
                            align={headCell.numeric ? "right" : "left"}
                            padding={
                                headCell.disablePadding ? "none" : "normal"
                            }
                            sortDirection={
                                orderBy === headCell.sort_id ? order : false
                            }
                        >
                            <TableSortLabel
                                active={orderBy === headCell.sort_id}
                                direction={
                                    orderBy === headCell.sort_id ? order : "asc"
                                }
                                onClick={createSortHandler(headCell.sort_id!)}
                            >
                                {headCell.label}
                                {orderBy === headCell.sort_id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === "desc"
                                            ? "sorted descending"
                                            : "sorted ascending"}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ) : (
                        <TableCell
                            key={i}
                            align={headCell.numeric ? "right" : "left"}
                            padding={
                                headCell.disablePadding ? "none" : "normal"
                            }
                        >
                            {headCell.label}
                        </TableCell>
                    )
                )}
            </TableRow>
        </TableHead>
    );
}
