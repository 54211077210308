import {
    Avatar,
    Box,
    Button,
    Container,
    Card,
    CardContent,
    Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import {
    tournament_date,
    tournament_format,
    tournament_guide_link,
    tournament_rule,
} from "../../../utils/settings";
import ArticleIcon from "@mui/icons-material/Article";
import BuildIcon from "@mui/icons-material/Build";
import logo from "../../../assets/scioly_logo_border.png";

export default function TournamentInfo() {
    return (
        <Card
            sx={{
                height: "100%",
                backgroundColor: "bg.card",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
            }}
        >
            <CardContent sx={{ pb: 0 }}>
                <Typography variant="h4" mb={1}>
                    TOURNAMENT
                </Typography>
                <Grid>
                    <Typography color="neutral.secondary" variant="caption">
                        Tournament Date:
                    </Typography>
                    <Typography color="neutral.main" variant="h5" mx={2}>
                        {tournament_date}
                    </Typography>
                    <Typography color="neutral.secondary" variant="caption">
                        Format:
                    </Typography>
                    <Typography color="neutral.main" variant="h5" mx={2}>
                        {tournament_format}{" "}
                        <Typography>({tournament_rule} rules)</Typography>
                    </Typography>
                </Grid>
            </CardContent>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row-reverse",
                }}
            >
                <Button
                    sx={{ mr: 2, mb: 2 }}
                    onClick={(e) => {
                        window.open(`${tournament_guide_link}`);
                    }}
                >
                    View Tournament Guide
                </Button>
            </Box>
        </Card>
    );
}
