import React, { useEffect, useState } from "react";
import {
    Avatar,
    Box,
    Button,
    Container,
    Card,
    CardContent,
    Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { tournament_countdown } from "../../../utils/settings";

type LabeledNumberProps = {
    number: number;
    label: string;
};

function LabeledNumber(props: LabeledNumberProps) {
    return (
        <Box
            //height={"90px"}
            sx={{ mx: "auto" }}
            display="flex"
            flexDirection={"column"}
            justifyContent="space-evenly"
        >
            <Typography variant="h3" fontSize="32pt" align="center">
                {props.number}
            </Typography>
            <Typography variant="h6" fontSize="9pt" align="center">
                {props.label}
            </Typography>
        </Box>
    );
}

const getDaysHoursMinsSecs = (countDown: number) => {
    // calculate time left
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
        (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

    return [days, hours, minutes, seconds];
};

export default function Countdown() {
    const countdownDate = Date.parse(tournament_countdown);

    const [countdown, setCountdown] = useState(
        getDaysHoursMinsSecs(
            countdownDate >= Date.now() ? countdownDate - Date.now() : 0
        )
    );

    useEffect(() => {
        if (countdownDate >= Date.now()) {
            const interval = setInterval(() => {
                setCountdown(
                    getDaysHoursMinsSecs(
                        countdownDate >= Date.now()
                            ? countdownDate - Date.now()
                            : 0
                    )
                );
            }, 1000);

            return () => clearInterval(interval);
        }
    }, []);

    return (
        <Card
            sx={{
                height: "100%",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
            }}
        >
            <CardContent sx={{ height: "100%" }}>
                <Grid sx={{ height: "70%" }}>
                    <Typography variant="h4" mb={1}>
                        COUNTDOWN
                    </Typography>
                    <Box
                        sx={{
                            height: "100%",
                            display: "flex",
                            justifyContent: "space-evenly",
                            flexDirection: "column",
                        }}
                    >
                        <Box display={"flex"}>
                            <LabeledNumber
                                number={countdown[0]}
                                label={"Days"}
                            />
                            <Typography
                                fontSize={"20pt"}
                                component={Box}
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-evenly",
                                }}
                            >
                                :
                            </Typography>
                            <LabeledNumber
                                number={countdown[1]}
                                label={"Hours"}
                            />
                            <Typography
                                fontSize={"20pt"}
                                component={Box}
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-evenly",
                                }}
                            >
                                :
                            </Typography>
                            <LabeledNumber
                                number={countdown[2]}
                                label={"Mins"}
                            />
                            <Typography
                                fontSize={"20pt"}
                                component={Box}
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-evenly",
                                }}
                            >
                                :
                            </Typography>
                            <LabeledNumber
                                number={countdown[3]}
                                label={"Secs"}
                            />
                        </Box>
                    </Box>
                </Grid>
            </CardContent>
        </Card>
    );
}
