import React from "react";
import PropTypes from "prop-types";
import { Edit, Delete } from "@mui/icons-material";
import { IconButton } from "@mui/material";

import RosterTableOptionDeleteDialog from "./options/DeleteOptionDialog";
import RosterTableOptionEditDialog from "./options/EditOptionDialog";
import { Student } from "../../types/student";

type TableOptionsProps = {
    student: Student;
    selected: Set<string>;
    students_in_team: Student[];
};

export default function RosterTableOptions(props: TableOptionsProps) {
    const { student, selected } = props;

    const [openDeleteConfirm, setOpenDeleteConfirm] = React.useState(false);
    const [openEditConfirm, setOpenEditConfirm] = React.useState(false);

    return (
        <>
            <IconButton
                onClick={() => setOpenEditConfirm(true)}
                disabled={selected.size > 0}
            >
                <Edit />
            </IconButton>
            <IconButton
                onClick={() => setOpenDeleteConfirm(true)}
                disabled={selected.size > 0}
            >
                <Delete />
            </IconButton>
            <RosterTableOptionEditDialog
                student={student}
                openConfirm={openEditConfirm}
                setOpenConfirm={setOpenEditConfirm}
                students_in_team={props.students_in_team}
            />
            <RosterTableOptionDeleteDialog
                student={student}
                openConfirm={openDeleteConfirm}
                setOpenConfirm={setOpenDeleteConfirm}
            />
        </>
    );
}
