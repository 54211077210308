import React, { useState } from "react";
import { Box, Button, Grid, TextField } from "@mui/material";

import { LoginData } from "../../types/authentication";

type LoginProp = {
    handleLogin: (formData: LoginData) => void;
};

type LoginForm = {
    email: string;
    password: string;
};

function LoginInfo(props: LoginProp) {
    const getFormData = (e: React.SyntheticEvent<HTMLFormElement>) => {
        const data = new FormData(e.currentTarget);
        return {
            email: String(data.get("email")).trim(),
            password: String(data.get("password")),
        };
    };

    const initialError = {
        email: "",
        password: "",
    };
    const [formErrors, setFormErrors] = useState(initialError);

    const validateForm = (data: LoginForm) => {
        let result = true;
        let newErrors = { ...formErrors };
        if (
            data.email === "" ||
            !data.email.match(
                /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
            )
        ) {
            newErrors.email = "Please enter a valid email.";
            result = false;
        }
        if (data.password === "") {
            newErrors.password = "Please enter a password.";
            result = false;
        }
        setFormErrors(newErrors);
        return result;
    };

    const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault();
        const data: LoginData = getFormData(e);
        if (validateForm(data)) {
            props.handleLogin(data);
        }
    };

    return (
        <>
            <Box
                component="form"
                noValidate
                maxWidth="450px"
                my={3}
                onSubmit={handleSubmit}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            type="email"
                            helperText={formErrors.email}
                            error={formErrors.email !== ""}
                            FormHelperTextProps={{
                                sx: { fontSize: 14 },
                            }}
                            onChange={(e) => {
                                if (e.target.value !== "") {
                                    setFormErrors({
                                        ...formErrors,
                                        email: "",
                                    });
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            id="password"
                            label="Password"
                            name="password"
                            type="password"
                            helperText={formErrors.password}
                            error={formErrors.password !== ""}
                            FormHelperTextProps={{
                                sx: { fontSize: 14 },
                            }}
                            onChange={(e) => {
                                if (e.target.value !== "") {
                                    setFormErrors({
                                        ...formErrors,
                                        password: "",
                                    });
                                }
                            }}
                        />
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3, fontSize: 16 }}
                        fullWidth
                    >
                        Sign In
                    </Button>
                </Box>
            </Box>
        </>
    );
}

export default LoginInfo;
