import React, { useContext, useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import { auth } from "../firebase";
import { db } from "../firebase";
import { doc, onSnapshot } from "firebase/firestore";

import store from "../redux/store";
import { useAppSelector } from "../redux/hooks";
import { loginSuccess } from "../redux/auth/authAction";
import {
    loadUserSuccess,
    loadUserRequest,
    loadSchoolRequest,
    loadSchoolSuccess,
} from "../redux";

type AuthProviderProps = {
    children: React.ReactNode;
};

export const AuthContext = React.createContext<firebase.User | null>(null);

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = (props: AuthProviderProps) => {
    const [currentUser, setCurrentUser] = useState<firebase.User | null>(null);
    const userInfo = useAppSelector((state) => state.user.user);
    const loadingSchool = useAppSelector((state) => state.user.loading_school);
    const loadingUser = useAppSelector((state) => state.user.loading_user);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
            setCurrentUser(firebaseUser);
            if (firebaseUser !== null) {
                store.dispatch(loginSuccess(firebaseUser));
                store.dispatch(loadUserRequest());
                store.dispatch(loadSchoolRequest());
            }
        });

        return unsubscribe;
    }, []);

    useEffect(() => {
        if (currentUser?.email) {
            const user_ref = doc(db, "users", currentUser!.uid);

            onSnapshot(user_ref, (user_doc) => {
                const data = user_doc.data();
                if (data !== undefined && data !== null) {
                    store.dispatch(loadUserSuccess(currentUser!.uid, data));
                }
            });
        }
    }, [currentUser]);

    useEffect(() => {
        if (
            !loadingUser &&
            loadingSchool &&
            userInfo !== null &&
            userInfo !== undefined &&
            currentUser?.email
        ) {
            const school_ref = doc(db, "schools", userInfo.school);

            onSnapshot(school_ref, (school_doc) => {
                const school_data = school_doc.data();
                if (school_data !== undefined) {
                    store.dispatch(
                        loadSchoolSuccess(school_doc.id, school_data)
                    );
                }
            });
        }
    }, [userInfo, currentUser, loadingSchool, loadingUser]);

    return (
        <AuthContext.Provider value={currentUser}>
            {props.children}
        </AuthContext.Provider>
    );
};
