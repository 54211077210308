import * as React from "react";

import { Box, TableCell, TableHead, TableRow } from "@mui/material";
import { useAppSelector } from "../../redux/hooks";

export default function SelfScheduleTableHead() {
    const team_number = useAppSelector(
        (state) => state.user.school.team_number
    );
    const number_of_teams = team_number.length;

    return (
        <TableHead>
            <TableRow>
                <TableCell />
                <TableCell align={"left"} padding="normal">
                    Event
                </TableCell>
                <TableCell align={"left"} padding="normal">
                    Location
                </TableCell>
                <TableCell align={"center"} padding="normal">
                    Team A
                </TableCell>
                {number_of_teams === 2 ? (
                    <TableCell align={"center"} padding="normal">
                        Team B
                    </TableCell>
                ) : null}
            </TableRow>
        </TableHead>
    );
}
