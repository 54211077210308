import {
    LOAD_USER_FAILURE,
    LOAD_USER_SUCCESS,
    LOAD_USER_REQUEST,
    CLEAR_USER_DATA,
    LOAD_SCHOOL_FAILURE,
    LOAD_SCHOOL_SUCCESS,
    LOAD_SCHOOL_REQUEST,
    CLEAR_SCHOOL_DATA,
    LOGOUT,
} from "./userReducer";
import { User } from "../../types/users";
import { School } from "../../types/school";
import { DocumentData } from "firebase/firestore";

export const loadUserRequest = () => {
    return {
        type: LOAD_USER_REQUEST,
    };
};

export const loadUserSuccess = (id: string, user: DocumentData) => {
    return {
        type: LOAD_USER_SUCCESS,
        payload: {
            id: id,
            email: user.email,
            first_name: user.first_name,
            last_name: user.last_name,
            phone_number: user.phone_number,
            role: user.role,
            school: user.school,
        } as User,
    };
};

export const loadUserFailure = () => {
    return {
        type: LOAD_USER_FAILURE,
    };
};

export const clearUserData = () => {
    return {
        type: CLEAR_USER_DATA,
    };
};

export const loadSchoolRequest = () => {
    return {
        type: LOAD_SCHOOL_REQUEST,
    };
};

export const loadSchoolSuccess = (id: string, school: DocumentData) => {
    return {
        type: LOAD_SCHOOL_SUCCESS,
        payload: {
            id: id,
            name: school.name,
            coaches: school.coaches,
            students: school.students,
            payment: school.payment,
            team_number: school.team_number,
            self_schedule: school.self_schedule,
        } as School,
    };
};

export const loadSchoolFailure = () => {
    return {
        type: LOAD_SCHOOL_FAILURE,
    };
};

export const clearSchoolData = () => {
    return {
        type: CLEAR_SCHOOL_DATA,
    };
};

export const logout_user = () => {
    return {
        type: LOGOUT,
    };
};
