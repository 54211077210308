import {
    addDoc,
    setDoc,
    updateDoc,
    arrayUnion,
    arrayRemove,
    collection,
    doc,
    query,
    orderBy,
    onSnapshot,
    where,
    getDocs,
} from "firebase/firestore";
import React from "react";
import { db } from "../firebase";
import store from "../redux/store";
import { setError, clearError } from "../redux";

import { School } from "../types/school";

export const getAllSchools = async () => {
    const schoolsQuery = query(
        collection(db, "schools"),
        orderBy("team_number")
    );

    try {
        const schoolArray = await getDocs(schoolsQuery);
        const schools = await schoolArray.docs.map(
            (doc) => ({ id: doc.id, ...doc.data() } as School)
        );
        return schools;
    } catch (error) {
        console.error(error);
    }
};

// Toggle payment status
export const updatePaymentStatus = async (
    schoolId: string,
    newStatus: string
) => {
    const ref = doc(db, "schools", schoolId);

    let data_to_save = {
        payment: newStatus,
    };

    try {
        await updateDoc(ref, data_to_save);
    } catch (error) {
        console.error(error);
    }
};

// Set self schedule
export const setSelfSchedule = async (
    schoolId: string,
    clickedSlot: number, // 0 through 5
    event: string, //event label
    team: number, //0 or 1
    team_number: string,
    oldSlots_copy: any
) => {
    const ref = doc(db, "schools", schoolId);
    const ref_ss = doc(db, "selfschedule", event);

    let new_slots = oldSlots_copy; //oldSlots_copy is already a copy so reference is fine
    // if clicked slot is already selected, then want to unselect
    let new_selfschedule: { [index: string]: any } = {};
    if (new_slots[event][team] === clickedSlot) {
        new_slots[event][team] = -1;
        new_selfschedule[`slot${clickedSlot + 1}`] = arrayRemove(team_number);
    } else {
        new_slots[event][team] = clickedSlot;
        new_selfschedule.slot1 = arrayRemove(team_number);
        new_selfschedule.slot2 = arrayRemove(team_number);
        new_selfschedule.slot3 = arrayRemove(team_number);
        new_selfschedule.slot4 = arrayRemove(team_number);
        new_selfschedule.slot5 = arrayRemove(team_number);
        new_selfschedule.slot6 = arrayRemove(team_number);
        new_selfschedule[`slot${clickedSlot + 1}`] = arrayUnion(team_number);
    }

    let data_to_save = {
        self_schedule: new_slots,
    };

    try {
        await updateDoc(ref, data_to_save);
        await updateDoc(ref_ss, new_selfschedule);
    } catch (error: any) {
        console.error(error);
        let message = "";
        switch (error.code) {
            default: {
                message = error.message;
                break;
            }
        }
        store.dispatch(setError(message));
        console.error(error);
    }
};

export const getSelfScheduleCapAndSetState = async (
    setCap: React.Dispatch<React.SetStateAction<{}>>,
    maxcap: number
) => {
    const selfScheduleQuery = query(collection(db, "selfschedule"));

    try {
        await onSnapshot(selfScheduleQuery, (querySnapshot) => {
            let result: { [index: string]: any } = {};
            querySnapshot.docs.map(
                (doc) =>
                    (result[doc.id] = [
                        maxcap - doc.data().slot1.length,
                        maxcap - doc.data().slot2.length,
                        maxcap - doc.data().slot3.length,
                        maxcap - doc.data().slot4.length,
                        maxcap - doc.data().slot5.length,
                        maxcap - doc.data().slot6.length,
                    ])
            );
            setCap(result);
        });
    } catch (error: any) {
        let message = "";
        switch (error.code) {
            default: {
                message = error.message;
                break;
            }
        }
        store.dispatch(setError(message));
        console.error(error);
    }
};

export const releaseFormCompletion = async (
    school_id: string,
    setFormCompletion: React.Dispatch<React.SetStateAction<string>>
) => {
    const userQuery = query(
        collection(db, "students"),
        where("school", "==", school_id)
    );

    try {
        await onSnapshot(userQuery, (querySnapshot) => {
            const total_students = querySnapshot.docs.length;
            const completed_forms = querySnapshot.docs.filter(
                (doc) => doc.data().release_form !== ""
            ).length;
            setFormCompletion(`${completed_forms}/${total_students}`);
        });
    } catch (error: any) {
        let message = "";
        switch (error.code) {
            default: {
                message = error.message;
                break;
            }
        }
        store.dispatch(setError(message));
        console.error(error);
    }
};
