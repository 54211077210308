import * as React from "react";

import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Select,
    MenuItem,
    SelectChangeEvent,
} from "@mui/material";
import Paper from "@mui/material/Paper";

import SeverityPill from "../SeverityPill";

import { School } from "../../types/school";
import { updatePaymentStatus, releaseFormCompletion } from "../../utils/school";

type SchoolsTableRowProps = {
    school: School;
};

export default function SchoolsTableRow(props: SchoolsTableRowProps) {
    const [formCompletion, setFormCompletion] = React.useState("");

    React.useEffect(() => {
        releaseFormCompletion(props.school.id, setFormCompletion);
    }, []);

    const handleSelectChange = (
        event: SelectChangeEvent,
        school_id: string
    ) => {
        updatePaymentStatus(school_id, event.target.value);
    };

    return (
        <TableRow hover role="checkbox" tabIndex={-1} key={props.school.name}>
            <TableCell component="th" scope="row" padding="none">
                {props.school.name}
            </TableCell>
            <TableCell align="right">
                {props.school.team_number.length}
            </TableCell>
            <TableCell align="right">
                {props.school.team_number.join(", ")}
            </TableCell>
            <TableCell align="right">{formCompletion}</TableCell>
            <TableCell align="left">
                <Select
                    size="small"
                    value={props.school.payment}
                    onChange={(event) => {
                        handleSelectChange(event, props.school.id);
                    }}
                    sx={{ width: "170px" }}
                >
                    <MenuItem value={"not processed"}>
                        <SeverityPill color="error">Not Processed</SeverityPill>
                    </MenuItem>
                    <MenuItem value={"completed"}>
                        <SeverityPill color="success">Completed</SeverityPill>
                    </MenuItem>
                </Select>
            </TableCell>
        </TableRow>
    );
}
