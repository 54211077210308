export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = "asc" | "desc";

export function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (
    a: { [key in Key]: number | string | boolean },
    b: { [key in Key]: number | string | boolean }
) => number {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly

// Support for IE11 has ended already so this function is not used at the moment
export function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export const headCells = [
    {
        id: "index",
        align: "center",
        disablePadding: true,
    },
    {
        id: "first",
        disablePadding: false,
        label: "Name",
        align: "left",
    },
    {
        id: "grade",
        disablePadding: false,
        label: "Grade",
        align: "center",
    },
    {
        id: "release_form",
        disablePadding: false,
        label: "Release\u00a0Form",
        align: "center",
    },
    {
        id: "alternate",
        disablePadding: false,
        label: "Alternate",
        align: "center",
    },
    {
        id: "options",
        label: "Options",
        align: "center",
    },
];
