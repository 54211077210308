import React from "react";
import { Box, Typography, Link } from "@mui/material";

export default function FormFooter() {
    const date = new Date();
    return (
        <Box
            mx={2}
            my={1}
            sx={{ display: "flex", justifyContent: "space-between" }}
        >
            <Typography fontSize={12} color="#CCCCCC">
                &copy; MIT Science Olympiad 2015-{date.getFullYear()}
            </Typography>
            <Link
                href="https://scioly.mit.edu/privacy"
                variant="body1"
                fontSize={12}
            >
                Privacy Policy
            </Link>
        </Box>
    );
}
