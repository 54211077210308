import React from "react";
import { Box } from "@mui/system";
import { motion } from "framer-motion";

const transition = { duration: 0.4, ease: [0.43, 0.13, 0.23, 0.96] };

export const pageVariant = {
    hidden: { opacity: 0 },
    show: {
        transition: {
            staggerChildren: 0.1,
        },
        opacity: 1,
    },
    close: { opacity: 0 },
};

export const cardVariant = {
    hidden: { opacity: 0, x: -20, transition },
    show: { opacity: 1, x: 0, transition },
    close: {
        opacity: 0,
        x: 20,
        transition,
    },
};

type AnimatedPageWrapperProps = {
    children: React.ReactNode;
};

export default function AnimatedPageWrapper(props: AnimatedPageWrapperProps) {
    return (
        <Box
            component={motion.div}
            variants={pageVariant}
            initial={"hidden"}
            animate={"show"}
            exit={"close"}
        >
            {props.children}
        </Box>
    );
}
