import React from "react";
import { styled } from "@mui/material/styles";
import { AppBar, IconButton, Toolbar, Typography, Box } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { motion, AnimatePresence } from "framer-motion";

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
}));

export const DashboardNavbar = (props: any) => {
    const { onSidebarOpen, ...other } = props;
    const [showFullTitle, setShowFullTitle] = React.useState(false);

    const changingTitle = () => {
        const full_title = {
            hidden: { opacity: 0, y: 10 },
            visible: {
                opacity: 1,
                y: 0,
            },
            end: { opacity: 0, y: 10 },
        };
        const default_title = {
            hidden: { opacity: 0, y: -10 },
            visible: {
                opacity: 1,
                y: 0,
            },
            end: { opacity: 0, y: -10 },
        };

        return showFullTitle ? (
            <motion.div
                initial="hidden"
                animate="visible"
                variants={full_title}
                transition={{ delay: 0, duration: 0.25 }}
                exit="end"
                key="full_title"
            >
                <Typography display="inline" variant="h4">
                    I
                </Typography>
                <Typography display="inline" variant="h3" fontSize={"15pt"}>
                    ntegrated{" "}
                </Typography>
                <Typography display="inline" variant="h4">
                    S
                </Typography>
                <Typography display="inline" variant="h3" fontSize={"15pt"}>
                    cience
                    {"\u00a0"}
                </Typography>
                <Typography display="inline" variant="h4">
                    O
                </Typography>
                <Typography display="inline" variant="h3" fontSize={"15pt"}>
                    lympiad{" "}
                </Typography>
                <Typography display="inline" variant="h4">
                    TO
                </Typography>
                <Typography display="inline" variant="h3" fontSize={"15pt"}>
                    urnament{" "}
                </Typography>
                <Typography display="inline" variant="h4">
                    P
                </Typography>
                <Typography display="inline" variant="h3" fontSize={"15pt"}>
                    lanning
                    {"\u00a0"}
                </Typography>
                <Typography display="inline" variant="h4">
                    E
                </Typography>
                <Typography display="inline" variant="h3" fontSize={"15pt"}>
                    nvironment
                </Typography>
            </motion.div>
        ) : (
            <motion.div
                initial="visible"
                animate="visible"
                variants={default_title}
                transition={{ delay: 0, duration: 0.25 }}
                exit="end"
                key="default_title"
            >
                <Typography variant="h4">ISOTOPE</Typography>
            </motion.div>
        );
    };

    return (
        <>
            <DashboardNavbarRoot
                sx={{
                    left: {
                        lg: 240,
                    },
                    width: {
                        lg: "calc(100% - 240px)",
                    },
                }}
                {...other}
            >
                <Toolbar
                    disableGutters
                    sx={{
                        height: 64,
                        left: 0,
                        px: 2,
                        backgroundColor: "bg.sidebar",
                    }}
                    onMouseOut={() => {
                        setShowFullTitle(false);
                    }}
                    onMouseOver={() => {
                        setShowFullTitle(true);
                    }}
                >
                    <IconButton
                        onClick={onSidebarOpen}
                        sx={{
                            display: {
                                xs: "inline-flex",
                                lg: "none",
                            },
                        }}
                    >
                        <MenuIcon fontSize="small" />
                    </IconButton>
                    <AnimatePresence mode="wait">
                        {"ontouchstart" in document.documentElement ? (
                            <Typography variant="h4">ISOTOPE</Typography>
                        ) : (
                            changingTitle()
                        )}
                    </AnimatePresence>
                </Toolbar>
            </DashboardNavbarRoot>
        </>
    );
};
