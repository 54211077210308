const month_to_string: { [key: string]: string } = {
    "01": "January",
    "02": "February",
    "03": "March",
    "04": "April",
    "05": "May",
    "06": "June",
    "07": "July",
    "08": "August",
    "09": "September",
    "10": "October",
    "11": "November",
    "12": "December",
};

export const numeric_date_to_string = (numeric_date: string) => {
    const [year, month, day] = numeric_date.split("-");
    return `${month_to_string[month]} ${day}, ${year}`;
};
