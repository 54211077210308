import { AnyAction } from "redux";

type popupState = {
    error: string;
    info: string;
    warning: string;
    success: string;
};

const initialState: popupState = {
    error: "",
    info: "",
    warning: "",
    success: "",
};

export const SET_ERROR = "popup/set_error";
export const CLEAR_ERROR = "popup/clear_error";
export const SET_SUCCESS = "popup/set_success";
export const CLEAR_SUCCESS = "popup/clear_success";

export default function popupReducer(state = initialState, action: AnyAction) {
    switch (action.type) {
        case SET_ERROR: {
            return {
                ...state,
                error: action.payload,
                open: true,
            };
        }
        case SET_SUCCESS: {
            return {
                ...state,
                success: action.payload,
            };
        }
        case CLEAR_ERROR: {
            return {
                ...state,
                error: "",
            };
        }
        case CLEAR_SUCCESS: {
            return {
                ...state,
                success: "",
            };
        }
        default:
            return state;
    }
}
