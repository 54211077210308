import React, { useState, useEffect } from "react";
import {
    Box,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    Typography,
    Select,
    MenuItem,
} from "@mui/material";
import { Check } from "@mui/icons-material";
import { motion, AnimatePresence } from "framer-motion";

import RosterTableHead from "./RosterTableHead";
import RosterTableToolbar from "./RosterTableToolbar";
import RosterTableOptions from "./RosterTableOptions";
import RosterTableAddStudent from "./AddStudent";
import SeverityPill from "../SeverityPill";

import { getComparator } from "./utils";

import { Student } from "../../types/student";
import { Order } from "../users/utils";
import { School } from "../../types/school";
import { getAllSchools } from "../../utils/school";
import { getStudentsAndSetState } from "../../utils/roster";
import { useAppSelector } from "../../redux/hooks";

import { toggleFormStatus } from "../../utils/roster";

export default function RosterTable() {
    const [order, setOrder] = useState<Order>("asc");
    const [orderBy, setOrderBy] = useState<keyof Student>("first");

    const role = useAppSelector((state) => state.user.user.role);
    const user_school = useAppSelector((state) => state.user.school);
    const [team, setTeam] = useState<"A" | "B">("A");
    const [students, setStudents] = useState<Student[]>([]);
    const [schools, setSchools] = useState<School[]>([]);
    const [selectedSchool, setSelectedSchool] = useState<School>(user_school);

    useEffect(() => {
        if (role === "admin") {
            getAllSchools().then((allSchools: School[] | undefined) => {
                if (allSchools !== undefined) {
                    setSchools(allSchools);
                    setSelectedSchool(allSchools[0]);
                }
            });
        }
    }, []);

    useEffect(() => {
        getStudentsAndSetState(setStudents, selectedSchool.id);
    }, [selectedSchool]);

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Student
    ) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const [selected, setSelected] = useState(new Set<string>());
    const toggleStudent = (student_id: any) => {
        if (selected.has(student_id)) {
            selected.delete(student_id);
            setSelected(new Set(selected));
        } else {
            selected.add(student_id);
            setSelected(new Set(selected));
        }
    };

    return (
        <Box sx={{ width: "100%" }}>
            {role === "admin" ? (
                <Box sx={{ pl: 2, py: 2 }}>
                    <Select
                        value={selectedSchool.id}
                        onChange={(event) => {
                            setSelectedSchool(
                                schools !== undefined
                                    ? schools.find(
                                          (s) => s.id === event.target.value
                                      )
                                    : user_school
                            );
                        }}
                    >
                        {schools.map((t) => {
                            return (
                                <MenuItem value={t.id} key={t.id}>
                                    {`${t.name} (${t.team_number.join(", ")})`}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </Box>
            ) : null}
            <Paper sx={{ width: "100%" }}>
                <RosterTableToolbar
                    team={team}
                    setTeam={setTeam}
                    selected={selected}
                    setSelected={setSelected}
                    num_teams={selectedSchool.team_number.length}
                    students={students}
                />
                <TableContainer>
                    <Table
                        size={"small"}
                        // stickyHeader
                    >
                        <RosterTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            student_ids={students
                                .filter((s) => s.team === team)
                                .map((s) => s.id)}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <TableBody>
                            <AnimatePresence mode="wait">
                                {students
                                    .filter((s) => s.team === team)
                                    .sort(getComparator(order, orderBy))
                                    .map((row, index) => {
                                        const labelId = `student-row-${index}`;
                                        return (
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={row.id}
                                                component={motion.tr}
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 1 }}
                                                exit={{ opacity: 0 }}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={selected.has(
                                                            row.id
                                                        )}
                                                        onChange={() => {
                                                            toggleStudent(
                                                                row.id
                                                            );
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    padding="checkbox"
                                                >
                                                    {index + 1}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    id={labelId}
                                                >
                                                    <Typography
                                                        sx={{
                                                            maxWidth: "200px",
                                                            whiteSpace:
                                                                "nowrap",
                                                            overflow: "hidden",
                                                            textOverflow:
                                                                "ellipsis",
                                                        }}
                                                    >
                                                        {row.first}&nbsp;
                                                        {row.last}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.grade}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {role === "admin" ? (
                                                        <Select
                                                            size="small"
                                                            value={
                                                                row.release_form ===
                                                                ""
                                                                    ? "incomplete"
                                                                    : "complete"
                                                            }
                                                            disabled={true}
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                toggleFormStatus(
                                                                    row.id,
                                                                    event.target
                                                                        .value ===
                                                                        "incomplete"
                                                                        ? ""
                                                                        : "complete"
                                                                );
                                                            }}
                                                            sx={{
                                                                width: "150px",
                                                            }}
                                                        >
                                                            <MenuItem
                                                                value={
                                                                    "incomplete"
                                                                }
                                                            >
                                                                <SeverityPill color="error">
                                                                    Incomplete
                                                                </SeverityPill>
                                                            </MenuItem>
                                                            <MenuItem
                                                                value={
                                                                    "complete"
                                                                }
                                                            >
                                                                <SeverityPill color="success">
                                                                    complete
                                                                </SeverityPill>
                                                            </MenuItem>
                                                        </Select>
                                                    ) : (
                                                        <SeverityPill
                                                            color={
                                                                row.release_form
                                                                    ? "success"
                                                                    : "error"
                                                            }
                                                            sx={{
                                                                width: "85px",
                                                            }}
                                                        >
                                                            {row.release_form
                                                                ? "Complete"
                                                                : "Incomplete"}
                                                        </SeverityPill>
                                                    )}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.alternate ? (
                                                        <Check />
                                                    ) : null}
                                                </TableCell>
                                                <TableCell>
                                                    <RosterTableOptions
                                                        student={row}
                                                        selected={selected}
                                                        students_in_team={students.filter(
                                                            (s) =>
                                                                s.team === team
                                                        )}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </AnimatePresence>
                        </TableBody>
                    </Table>
                </TableContainer>
                <RosterTableAddStudent
                    team={team}
                    students_in_team={students.filter((s) => s.team === team)}
                    school_id={selectedSchool.id}
                />
            </Paper>
        </Box>
    );
}
