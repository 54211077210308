import React, { useEffect } from "react";
import {
    Avatar,
    Box,
    Button,
    Container,
    Card,
    CardContent,
    Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";

import { Student } from "../../../types/student";
import { useAppSelector } from "../../../redux/hooks";
import { getStudentsAndSetState } from "../../../utils/roster";
import { release_form_deadline } from "../../../utils/settings";

export default function ReleaseForm() {
    const navigate = useNavigate();
    const school_id = useAppSelector((state) => state.user.school.id);
    const [students, setStudents] = React.useState<Student[]>([]);
    const [team, setTeam] = React.useState<"A" | "B">("A");

    useEffect(() => {
        getStudentsAndSetState(setStudents, school_id);
    }, []);

    return (
        <Card
            sx={{
                height: "100%",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
            }}
        >
            <CardContent sx={{ pb: 0 }}>
                <Typography variant="h4" mb={1}>
                    RELEASE FORM
                </Typography>
                <Grid>
                    <Typography color="neutral.secondary" variant="caption">
                        Forms Completed:
                    </Typography>
                    <Typography color="neutral.main" variant="h3" mx={2}>
                        {students.filter((s) => s.release_form !== "").length}/
                        {students.length}
                    </Typography>
                    <Typography color="neutral.secondary" variant="caption">
                        Deadline:
                    </Typography>
                    <Typography color="neutral.main" variant="h5" mx={2}>
                        {release_form_deadline}
                    </Typography>
                </Grid>
            </CardContent>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row-reverse",
                }}
            >
                <Button
                    sx={{ mr: 2, mb: 2 }}
                    onClick={() => {
                        navigate("/roster");
                    }}
                >
                    View Full Roster
                </Button>
            </Box>
        </Card>
    );
}
